import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import type { Transaction } from '@tiptap/pm/state';
import { type Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';
import { Toolbar } from './Toolbar';

export interface RichTextProps {
  content: string;
  placeholder?: string;
  isEditable?: boolean;
  /** This also needs `isEditable` to be true to show the toolbar  */
  shouldShowToolbar?: boolean;
  children?: (editor: Editor) => JSX.Element;
  className?: string;
  onChange?: (props: {
    editor: Editor;
    transaction: Transaction;
  }) => void;
  onBlur?: (props: {
    editor: Editor;
    event: FocusEvent;
    transaction: Transaction;
  }) => void;
  onImageUpload?: (file: File) => Promise<string>;
}

export const RichText = (
  {
    content,
    placeholder,
    isEditable,
    className,
    onChange = () => {},
    onBlur = () => {},
    onImageUpload,
    shouldShowToolbar,
    children,
  }: RichTextProps,
) => {
  const extensions = [
    StarterKit,
    Placeholder.configure({
      placeholder,
    }),
    Link.configure({
      protocols: ['http', 'https'],
      HTMLAttributes: {
        class: 'rte-link',
        rel: '',
        // Remove target entirely so links open in current tab
        target: null,
      },
    }),
    Image,
  ];

  const editor = useEditor({
    autofocus: false,
    editable: isEditable,
    editorProps: {
      attributes: {
        class: `${className} tiptap overflow-auto focus:outline-none`,
        'data-testid': 'tiptap-text-area',
      },
    },
    onUpdate: onChange,
    extensions,
    onBlur,
    content,
  });

  // Necessary as the tiptap editor doesn't automatically update the content when the content prop changes
  useEffect(() => {
    if (editor && !editor.isDestroyed) editor?.commands.setContent(content);
  }, [content, editor]);

  const isChildrenFunction = typeof children === 'function';

  return (
    <>
      {shouldShowToolbar && isEditable && <Toolbar editor={editor} onImageUpload={onImageUpload} />}
      <EditorContent editor={editor} />
      {isChildrenFunction && children(editor)}
    </>
  );
};
