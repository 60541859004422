import { useAppDispatch } from '@/state/hooks';
import { type ExternalInviteFormState, type PostInviteResponse, util } from '@/state/queries/invites.api';
import metrics from '@/util/metrics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/sharp-solid-svg-icons';
import type { SerializedError } from '@reduxjs/toolkit';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Input } from '../form';
import { addToastError, addToastSuccess } from '../Toast/utils';

interface InviteCompanyFormProps {
  closeModal: () => void;
  submitExternalInvite: (formState: ExternalInviteFormState) => Promise<{
    data?: PostInviteResponse;
    error?: FetchBaseQueryError | SerializedError;
  }>;
}

const emptyFormState = { firstName: '', lastName: '', organizationName: '', email: '' };
const SESSION_STORAGE_KEY = 'inviteCompanyFormState';

export const InviteCompanyForm = ({ closeModal, submitExternalInvite }: InviteCompanyFormProps) => {
  const getInitialFormState = () => {
    const savedFormState = sessionStorage.getItem(SESSION_STORAGE_KEY);
    if (savedFormState) {
      return JSON.parse(savedFormState);
    }
    return emptyFormState;
  };
  const [formState, setFormState] = useState(getInitialFormState());
  const dispatch = useAppDispatch();

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(formState));
  }, [formState]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await submitExternalInvite(formState);

      if (res?.error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((res?.error as any)?.data?.error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          addToastError((res?.error as any)?.data?.error);
        } else {
          addToastError('Error inviting company');
        }
        setFormState(emptyFormState);
        closeModal();
        return;
      }

      addToastSuccess('Company invited successfully');

      const responseUser = res?.data?.user;
      if (responseUser?.role === 'admin') {
        // If the user created was an admin, track that a new company was created
        metrics.track('External Company Invite Sent', {
          inviteeName: `${responseUser?.firstName} ${responseUser?.lastName}`,
          inviteeEmail: responseUser?.email,
          organizationName: responseUser?.organization?.name,
        });
      } else {
        metrics.track('External User Invite Sent', {
          inviteeName: `${responseUser?.firstName} ${responseUser?.lastName}`,
          inviteeEmail: responseUser?.email,
          organizationName: responseUser?.organization?.name,
        });
      }

      dispatch(
        util.updateQueryData('GetExternalInvites', null, (cacheData) => {
          cacheData.organizations?.push(res?.data?.organization);
          cacheData.users?.push(res?.data?.user);
        }),
      );
      sessionStorage.removeItem(SESSION_STORAGE_KEY);
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form className='flex flex-1' onSubmit={handleSubmit} data-testid='external-invite-modal'>
      <div className='flex flex-col flex-1 gap-5'>
        <h3 className='mb-0 text-3xl font-black font-effra'>Collaborate securely with people outside your company</h3>
        <div className='flex gap-[16px]'>
          <p className='effra-12'>
            Start seeing progress faster by inviting your collaborators to create or join their own company in
            Integrate. Don&apos;t worry, they won&apos;t see what you&apos;ve built unless you explicitly share it.
          </p>
          <div className='flex items-center text-white bg-tertiary p-[8px] pl-[4px] rounded-[4px] gap-[4px] effra-14 w-[333px] shrink-0'>
            <div className='flex justify-center items-center shrink-0 w-[36px] h-[36px]'>
              <FontAwesomeIcon icon={faUserPlus} className='text-[16px]' />
            </div>
            External collaborators and the things they own will display as green in your account.
          </div>
        </div>

        <div className='flex flex-col gap-5'>
          <div className='basis-0'>
            <Input
              id='first-name'
              name='firstName'
              type='text'
              placeholder='First Name'
              value={formState.firstName ?? ''}
              onChange={(e) => {
                const firstName = e.target.value;
                setFormState({ ...formState, firstName });
              }}
              autoComplete='first-name'
              required
            />
          </div>

          <div className='basis-0 shrink-[2]'>
            <Input
              id='last-name'
              name='lastName'
              type='text'
              placeholder='Last Name'
              value={formState.lastName ?? ''}
              onChange={(e) => {
                const lastName = e.target.value;
                setFormState({ ...formState, lastName });
              }}
              autoComplete='last-name'
              required
            />
          </div>

          <div className='basis-0'>
            <Input
              id='company-name'
              name='companyName'
              type='text'
              placeholder='Company Name'
              value={formState.organizationName ?? ''}
              onChange={(e) => {
                const organizationName = e.target.value;
                setFormState({ ...formState, organizationName });
              }}
              autoComplete='organization-title'
              required
            />
          </div>

          <div className='basis-0'>
            <Input
              id='email'
              name='email'
              type='email'
              value={formState.email ?? ''}
              placeholder='Email'
              onChange={(e) => {
                const email = e.target.value;
                setFormState({ ...formState, email });
              }}
              required
            />
          </div>
        </div>

        <button type='submit' className='w-auto btn-primary'>
          Invite External Company
        </button>
      </div>
    </form>
  );
};
