import { InviteCompanyForm } from '@/components/Company/InviteCompanyForm';
import Color from '@/components/types/Color.enum';
import { Button } from '@/designSystemComponents/Button';
import { useModal } from '@/hooks/useModal';
import { type ExternalInviteFormState, usePostExternalOrgInviteMutation } from '@/state/queries/invites.api';
import metrics from '@/util/metrics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/sharp-solid-svg-icons';
import { useRouter } from 'next/router';

export default function InviteExternalButton (): JSX.Element {
  const router = useRouter();
  const { showModal, closeModal } = useModal();
  const [submitExternalInvite] = usePostExternalOrgInviteMutation();

  const handleSubmitExternalInvite = async (formState: ExternalInviteFormState) =>
    await submitExternalInvite(formState);

  const handleNewExternalCollaboratorClick = () => {
    showModal(
      <InviteCompanyForm
        submitExternalInvite={handleSubmitExternalInvite}
        closeModal={closeModal}
      />,
      { className: 'w-[750px] !max-w-[750px]' },
    );
    metrics.track('sidenav - invite button clicked', {
      currentPath: router.pathname,
    });
  };

  return (
    <Button
      figmaProps={{ style: 'fill', size: 'xsmall' }}
      className='@expanded/sidenav:!w-full @expanded/sidenav:!h-[30px] !w-[24px] !h-[24px] !px-0'
      innerClassName='flex items-center gap-2'
      color={Color.TERTIARY}
      onClick={handleNewExternalCollaboratorClick}
    >
      <FontAwesomeIcon icon={faUserPlus} />
      <div className='hidden @expanded/sidenav:block'>Invite</div>
    </Button>
  );
}
