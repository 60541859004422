import type { Diff, FromTo, HardwareIntegration, ModularObject } from '@/__generated__/types';
import { DRIVER_SUBTYPE_TO_MODULE_MAP, type DriverFields, type RequirementFields } from './constants';
import type { ObjectCardSubDriverFragment } from './getObjectCardSubDrivers.generated';

export function addNewDriver (
  openNewDriverModal: () => void,
  objectId,
): void {
  const integrations = [];

  if (objectId) {
    const milestoneIntegration: HardwareIntegration = {
      parentId: objectId, // Set the integration parent to the object the button is for
      parentType: 'modular_object',
      objectType: 'modular_object', // objectId will be filled in on save
      isPending: true,
      asDriver: true,
    } as HardwareIntegration & { asDriver: true };
    integrations.push(milestoneIntegration);
  }

  openNewDriverModal();
}

export function shapeNewRequirement (driver: Partial<RequirementFields>, visibility = 'Private') {
  const subType = 'requirement';
  const moduleIdToName = DRIVER_SUBTYPE_TO_MODULE_MAP[subType];
  const data = Object.entries(driver || {})
    .reduce((acc, [key, value]) => {
      const moduleId = moduleIdToName[key];
      if (moduleId && value) {
        acc[moduleId] = { value };
      }
      return acc;
    }, {});

  const templateId = DRIVER_SUBTYPE_TO_MODULE_MAP[subType].subType;
  return {
    templateId,
    data,
    visibility,
    name: driver?.name,
    assigneeId: driver?.assigneeId || undefined,
  } as ModularObject;
}

export function shapeNewDriver (driver: Partial<DriverFields>, visibility = 'Private'): ModularObject {
  const subType = (driver?.subType ?? 'milestone').toLowerCase();
  const moduleIdToName = DRIVER_SUBTYPE_TO_MODULE_MAP[subType];
  const data = Object.entries(driver || {})
    .reduce((acc, [key, value]) => {
      const moduleId = moduleIdToName[key];
      if (moduleId && value) {
        acc[moduleId] = { value };
      }
      return acc;
    }, {});

  const templateId = DRIVER_SUBTYPE_TO_MODULE_MAP[subType].subType;
  return {
    templateId,
    data,
    visibility,
    name: driver?.name,
    startDate: driver?.startDate || undefined,
    targetDate: driver?.targetDate || undefined,
    assigneeId: driver?.assigneeId || undefined,
  } as ModularObject;
}

export function shapeNewMilestoneDiff (driver: Partial<DriverFields>, externalID, externalType): Diff {
  const diff = Object.entries(driver || {})
    .reduce<FromTo>((acc, [key, value]) => {
      const subType = (driver?.subType ?? 'milestone').toLowerCase();
      const moduleIdToName = DRIVER_SUBTYPE_TO_MODULE_MAP[subType];
      const moduleId = moduleIdToName[key];
      if (moduleId) {
        return {
          ...acc,
          to: {
            ...acc.to,
            name: driver?.name,
            startDate: driver?.startDate,
            targetDate: driver?.targetDate,
            assigneeId: driver?.assigneeId,
            data: { ...acc.to.data, [moduleId]: value },
            displayNames: { data: { ...acc.displayNames.data, [moduleId]: key } },
          },
        };
      }
      return acc;
    }, {
      to: {
        name: '',
        startDate: '',
        targetDate: '',
        assigneeId: '',
        data: {},
      },
      displayNames: { data: {} },
      from: undefined,
    });

  return { diff, externalID, externalType } as Diff;
}

export function flattenDrivers (
  drivers: ObjectCardSubDriverFragment[],
): ModularObject[] {
  const driversToReturn = [];

  drivers.forEach((driver) => {
    driversToReturn.push(driver);

    if (driver?.drivers?.edges.length > 0) {
      // @ts-expect-error - fix this later
      const childDrivers = flattenDrivers(driver.drivers?.edges.map(({ node }) => node));
      if (childDrivers?.length) {
        driversToReturn.push(...childDrivers);
      }
    }
  });

  return driversToReturn;
}
