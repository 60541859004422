export interface DriverFields {
  subType: string;
  name: string;
  startDate: string;
  targetDate: string;
  assigneeId: string;
}

export const REQUIREMENT_TEMPLATE_ID = '9deecd59-41ce-4c70-83eb-5d720c9f0c6a';
export const REQUIREMENT_NAME_MODULE_ID = '618959b0-e236-4d4c-acbb-181f035331cb';
export const REQUIREMENT_START_DATE_MODULE_ID = 'cb7d4acf-c074-4cf5-b1b7-97caa689d6a2';
export const REQUIREMENT_TARGET_DATE_MODULE_ID = 'b0c1b5d5-b8c9-4910-bcc5-d0ed38b3acf5';
export const REQUIREMENT_TYPE_MODULE_ID = 'e44d2e83-b95c-4044-9c26-999d1125f7a7';
export const REQUIREMENT_SHALL_MODULE_ID = '87355b6a-dc73-41db-92c0-ff8ecd149f9a';
export const REQUIREMENT_VERIFICATION_TYPE_MODULE_ID = '11b59a66-0056-451c-809b-06c50fd25522';
export const REQUIREMENT_VERIFICATION_STATUS_MODULE_ID = '2d655ccb-44be-45e3-9241-fd860c33f6e9';
export const REQUIREMENT_ASSIGNEE_MODULE_ID = '901bcd10-5134-48b2-9c47-351683ef6c47';
export const REQUIREMENT_NAME = '1103e288-9736-40e7-899d-979d4a03f316';

export interface RequirementFields {
  subType: string;
  shall: string;
  requirementType: string;
  verificationType: string;
  status: string;
  assigneeId: string;
  name: string;
}

type RequirementField = keyof RequirementFields;

export const REQUIREMENT_MODULE_ID_TO_NAME: Record<RequirementField, string> = {
  subType: REQUIREMENT_TEMPLATE_ID,
  requirementType: REQUIREMENT_TYPE_MODULE_ID,
  shall: REQUIREMENT_SHALL_MODULE_ID,
  verificationType: REQUIREMENT_VERIFICATION_TYPE_MODULE_ID,
  status: REQUIREMENT_VERIFICATION_STATUS_MODULE_ID,
  assigneeId: REQUIREMENT_ASSIGNEE_MODULE_ID,
  name: REQUIREMENT_NAME,
};

export const MILESTONE_TEMPLATE_ID = '438716b0-68d5-4bac-81a7-40edb767274d';
export const MILESTONE_NAME_MODULE_ID = '422f6666-82ce-4530-9e59-547f87246a31';
export const MILESTONE_START_DATE_MODULE_ID = '84527227-568b-426e-860b-c5ed95675bd4';
export const MILESTONE_TARGET_DATE_MODULE_ID = '94998c4b-4c80-416d-a195-ccc096bdcbe4';
export const MILESTONE_TYPE_MODULE_ID = '6ddb9263-2715-48cd-ac5b-2db08154b0e2';
export const MILESTONE_DESCRIPTION_MODULE_ID = '417d51c7-3f3f-4f33-96c4-00b79cee3bdd';
export const MILESTONE_STATUS_MODULE_ID = '76159f3a-3ebf-4329-a728-f87094d769cb';
export const MILESTONE_ASSIGNEE_MODULE_ID = '48c8489d-4130-4380-8e40-8231af290cbf';

export interface MilestoneFields {
  subType: string;
  description: string;
  type: string;
  status: string;
}

type MilestoneField = keyof MilestoneFields;

export const MILESTONE_MODULE_ID_TO_NAME: Record<MilestoneField, string> = {
  subType: MILESTONE_TEMPLATE_ID,
  type: MILESTONE_TYPE_MODULE_ID,
  description: MILESTONE_DESCRIPTION_MODULE_ID,
  status: MILESTONE_STATUS_MODULE_ID,
};

export const TASK_TEMPLATE_ID = '33214714-592e-458f-8de6-85e8ac45d471'; // This is the correct use of task (as in, type of driver)
export const TASK_DESCRIPTION_MODULE_ID = '00103b44-69c4-4608-9338-5e69c96b4b8f';
export const TASK_STATUS_MODULE_ID = 'fe8a27ea-3680-435a-8932-fa335da99460';

export interface DriverTemplateFields {
  subType: string;
  description: string;
  status: string;
}

type DriverField = keyof DriverTemplateFields;
export const TASK_MODULE_NAME_TO_ID: Record<DriverField, string> = {
  subType: TASK_TEMPLATE_ID,
  description: TASK_DESCRIPTION_MODULE_ID,
  status: TASK_STATUS_MODULE_ID,
};

export const DRIVER_SUBTYPE_TO_MODULE_MAP = {
  milestone: MILESTONE_MODULE_ID_TO_NAME,
  requirement: REQUIREMENT_MODULE_ID_TO_NAME,
  task: TASK_MODULE_NAME_TO_ID,
};
