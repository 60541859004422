import type { ModularObject } from '@/__generated__/types';
import { useEditContext } from '@/components';
import DisplayValue from '@/components/modules/DisplayValue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimer } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import dayjs from 'dayjs';
import { forwardRef, type MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import DetailItem from '../DetailItem';
import DurationInput from './DurationInput';
import DurationLock from './DurationLock';

interface ObjectDurationProps {
  updateTargetDate: (date: string) => Promise<void>;
  updateStartDate: (date: string) => Promise<void>;
  duration: string | null;
  setDuration: (duration: string) => void;
  objectCardData: ModularObject;
  afterChange?: () => void;
  onFocus?: () => void;
}

const ObjectDuration = forwardRef(({
  objectCardData,
  updateTargetDate,
  updateStartDate,
  duration,
  setDuration,
  afterChange,
  onFocus,
}: ObjectDurationProps, inputRef: MutableRefObject<HTMLInputElement>) => {
  const target = dayjs(objectCardData?.targetDate);
  const start = dayjs(objectCardData?.startDate);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const { canUserEdit } = useEditContext();

  useEffect(() => {
    if (isFocused && onFocus) {
      onFocus();
    }
  }, [isFocused, onFocus]);

  const handleDurationChange = useCallback((value: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (objectCardData?.durationLocked) return;

    timeoutRef.current = setTimeout(() => {
      if (!objectCardData?.startDate && objectCardData?.targetDate) {
        const newStartDate = target.subtract(Number(value), 'day').format();
        void updateStartDate(newStartDate);
      } else {
        const newTargetDate = start.add(Number(value), 'day').format();
        void updateTargetDate(newTargetDate);
      }

      if (afterChange) {
        afterChange();
      }
    }, 1000);

    setDuration(value);
  }, [
    objectCardData?.startDate,
    objectCardData?.targetDate,
    objectCardData?.durationLocked,
    updateStartDate,
    updateTargetDate,
    setDuration,
    start,
    target,
    afterChange,
  ]);

  const hasDates = objectCardData?.startDate && objectCardData?.targetDate;

  if (!canUserEdit) {
    const displayValue = duration ? `${duration} days` : '—';

    return (
      <DetailItem label='Duration'>
        <div className='flex items-center gap-[8px]'>
          {duration && <FontAwesomeIcon icon={faTimer} className='text-[14px]' />}
          <DisplayValue value={displayValue} />
        </div>
      </DetailItem>
    );
  }

  return (
    <DetailItem label='Duration'>
      <div
        className={cx(
          'flex items-center pl-[5px] h-[32px] gap-[8px] w-[15rem] relative effra-14 border cursor-text text-neutral-400 group/duration-input',
          {
            '!text-black border-transparent': duration,
            'hover:border-b-black hover:border-neutral-200': !isFocused && hasDates &&
              !objectCardData?.durationLocked,
            '!border-neutral-200 !border-b-primary': !objectCardData?.durationLocked && isFocused && hasDates,
            'border-neutral-200': !duration && hasDates && !objectCardData?.durationLocked,
          },
        )}
        onClick={() => {
          if (objectCardData?.durationLocked || !hasDates) return;

          setIsFocused(true);
          inputRef.current?.focus();
        }}
      >
        <FontAwesomeIcon icon={faTimer} className='text-[12px]' />
        <DurationInput
          duration={duration}
          setIsFocused={setIsFocused}
          setDuration={handleDurationChange}
          inputRef={inputRef}
          isLocked={objectCardData?.durationLocked}
        />
        {Boolean(duration || objectCardData?.durationLocked) && ( // Adding this || case due to an API bug that might persist in some customer's objects and we want to offer an escape hatch for them: https://linear.app/integrate/issue/ENG-5902/removing-objects-starttarget-date-with-locked-duration-ui-issue
          <DurationLock
            isLocked={objectCardData?.durationLocked}
            objectCardData={objectCardData}
          />
        )}
      </div>
    </DetailItem>
  );
});

export default ObjectDuration;

ObjectDuration.displayName = 'ObjectDuration';
