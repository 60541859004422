import type { DriverStatus, ModularObject } from '@/__generated__/types';
import { User as UserComponent } from '@/components';
import { Button } from '@/components/Button/Button';
import { useUserProfileDrawer } from '@/hooks/useUserProfileDrawer';
import { DriverPillColor } from '@/models/driver.model';
import cx from 'classnames';
import dayjs from 'dayjs';
import DriverStatusIcon from '../../DriverStatusIcon';

export enum ParentColor {
  Purple = 'purple',
  Teal = 'teal',
}

export interface DriverListRowLineProps {
  assigneeId?: string;
  ownerId?: string;
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, driver: ModularObject) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onParentClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  id: string;
  isChecked: boolean;
  name: string;
  parentColor: ParentColor;
  parentIcon: React.ReactNode;
  parentName?: string;
  pill: React.ReactNode;
  startDate: string;
  status: DriverStatus;
  targetDate: string;
  driver: ModularObject; // TODO: Use this and remove all the other props that can be sent through this
}

export default function DriverListRowLine (
  {
    assigneeId,
    className,
    onChange,
    onClick,
    onKeyDown,
    onParentClick,
    id,
    isChecked,
    name,
    parentColor,
    parentIcon,
    parentName,
    pill,
    startDate,
    status,
    targetDate,
    driver,
  }: Readonly<
    DriverListRowLineProps
  >,
): JSX.Element {
  const { openUserProfileDrawer } = useUserProfileDrawer();
  const parentTextColors = {
    [ParentColor.Purple]: 'text-primary',
    [ParentColor.Teal]: 'text-tertiary',
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event, driver);
  };

  const handleUserClick = (e): void => {
    e.stopPropagation();
    openUserProfileDrawer(assigneeId);
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      handleUserClick(e);
    }
  };

  return (
    <div
      className={cx('flex grow items-center gap-3 px-[18px] py-2 cursor-pointer hover:bg-zinc-50 w-full', className)}
      data-testid='driver-list-row-line'
    >
      {/* Checkbox */}
      <span className='flex shrink'>
        <input
          onChange={handleOnChange}
          name='selectedDrivers'
          id='selected-drivers'
          value={id}
          className={`input-checkbox custom-checkbox checked:bg-primary`}
          type='checkbox'
          checked={isChecked}
        />
      </span>
      {/* Driver Pill */}
      <span className='flex justify-end basis-[60px] shrink-0' data-testid='driver-list-pill-col'>
        {pill}
      </span>
      {/* Status Icon */}
      <span className={cx('flex basis-[36px] shrink-0 justify-center')} data-testid='status-icon'>
        <DriverStatusIcon status={status} color={DriverPillColor.Black} />
      </span>
      {/* Name */}
      <span
        className='flex items-center w-0 font-bold truncate grow shrink effra-14'
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={0}
      >
        {
          <span
            data-testid='driver-name'
            className={cx('truncate effra-14 font-bold', {
              'opacity-30': status === 'COMPLETE',
            })}
            title={name}
          >
            {name}
          </span>
        }
      </span>
      {/* Start Date */}
      <span
        data-testid='driver-start-date'
        className={cx('flex basis-[72px] shrink-0 font-effra text-[12px] truncate min-w-0', {
          'opacity-30': status === 'COMPLETE',
        })}
      >
        {startDate ? dayjs(startDate).format('MM/DD/YY') : '-'}
      </span>
      {/* Target Date */}
      <span
        data-testid='driver-target-date'
        className={cx('flex basis-[72px] shrink-0 font-effra text-[12px] truncate min-w-0', {
          'opacity-30': status === 'COMPLETE',
        })}
      >
        {targetDate ? dayjs(targetDate).format('MM/DD/YY') : '-'}
      </span>
      {/* Parent */}
      <span data-testid='driver-parent' className='flex min-w-0 basis-[100px] shrink-0'>
        <Button
          className={cx(parentTextColors[parentColor], {
            'opacity-30': status === 'COMPLETE',
          })}
          innerClassName='flex gap-3 items-center'
          onClick={onParentClick}
          title={parentName ?? '–'}
        >
          <span className='shrink-0'>
            {parentIcon}
          </span>
          <span className='font-bold truncate max-w-[125px] font-effra text-[14px]'>{parentName ?? '–'}</span>
        </Button>
      </span>
      {/* Assignee */}
      <span
        className={cx('flex min-w-min', {
          'opacity-50': status === 'COMPLETE' && driver?.assignee,
        })}
      >
        <button
          className='flex items-center space-x-3'
          onClick={(e) => {
            handleUserClick(e);
          }}
          onKeyDown={(e) => {
            handleKeyPress(e);
          }}
          tabIndex={0}
        >
          <UserComponent
            user={driver?.assignee}
            size='default'
            imageOnly
          />
        </button>
      </span>
    </div>
  );
}
