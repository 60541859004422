import { useObjectCardScrollContext } from '@/components/common/ModularObject/Card/ObjectCardScroll.context';
import CreateNewDriverModal from '@/components/modals/CreateNewDriverModal/CreateNewDriverModal';
import { useGetAllDriversQuery } from '@/components/Schedule/queries/getAllDrivers.generated';
import { Button } from '@/designSystemComponents/Button';
import { useModal } from '@/hooks/useModal';
import { useNestingMutations } from '@/hooks/useNestingMutations';
import { type Integration } from '@/models/integration.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { createRef, type Ref, useCallback, useEffect, useMemo, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import MegaModal from '../MegaModal/MegaModal';
import MegaModalEmptyList from '../MegaModal/MegaModalEmptyList/MegaModalEmptyList';
import MegaModalHeader from '../MegaModal/MegaModalHeader/MegaModalHeader';
import MegaModalSearchInput from '../MegaModal/MegaModalSearchInput/MegaModalSearchInput';
import SelectDriversContainer from './SelectDriversContainer';

interface SelectDriversToAddModalProps {
  hardwareId?: string;
  preselectedId?: string;
  onSubmit: (integrations: Integration[]) => void;
}

const DEFAULT_PAGE_SIZE = 20;
export default function SelectDriversToAddModal ({
  hardwareId,
  preselectedId,
  onSubmit,
}: Readonly<SelectDriversToAddModalProps>): JSX.Element {
  const inputRef: Ref<HTMLInputElement> = createRef();
  const [search, setSearch] = useState<string>('');
  const [selectedDrivers, setSelectedDrivers] = useState(preselectedId ? [preselectedId] : []);
  const { closeModal, showModal } = useModal();
  const { scrollToId } = useObjectCardScrollContext();
  const { openViewObjectCardDrawer } = useObjectCardContext();
  const { addChild } = useNestingMutations(hardwareId);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const { data, loading, previousData } = useGetAllDriversQuery({ fetchPolicy: 'cache-and-network' });
  const getAllDrivers = loading ? previousData?.getAllDrivers : data?.getAllDrivers;

  const [driverCountToShow, setDriverCountToShow] = useState(DEFAULT_PAGE_SIZE);
  const loadMoreDrivers = useCallback(() => {
    const isNextPageSizeLargerThanDriverList = driverCountToShow + DEFAULT_PAGE_SIZE > (getAllDrivers?.length || 0);
    if (isNextPageSizeLargerThanDriverList) {
      return setDriverCountToShow((getAllDrivers?.length || 0) - 1);
    }
    return setDriverCountToShow(driverCountToShow + DEFAULT_PAGE_SIZE);
  }, [driverCountToShow, getAllDrivers?.length]);
  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: true,
    onLoadMore: loadMoreDrivers,
    disabled: false,
  });

  const filteredDrivers = useMemo(() => {
    if (!getAllDrivers) return [];

    return getAllDrivers?.filter((driver) => {
      const driverName = driver?.name?.toLowerCase();
      const parentName = driver?.parent?.name?.toLowerCase();
      const searchLowerCase = search.toLowerCase();

      return (
        (driverName?.includes(searchLowerCase)) ||
        (parentName?.includes(searchLowerCase)) ||
        !searchLowerCase
      );
    }).sort((a, b) => (a.id === preselectedId ? -1 : b.id === preselectedId ? 1 : 0)).slice(0, driverCountToShow);
  }, [
    getAllDrivers,
    preselectedId,
    search,
    driverCountToShow,
  ]);

  const handleAddToObject = async () => {
    const driverIntegrations = selectedDrivers.map((driverID) => {
      const driverIntegration: Integration = {
        objectId: driverID,
        objectType: 'modular_object',
        parentId: hardwareId,
        parentType: 'modular_object',
        isPending: true,
        asDriver: true,
      };
      return driverIntegration;
    });

    scrollToId('drivers-section');
    onSubmit(driverIntegrations);
    closeModal();
  };

  const handleSelect = (e) => {
    const driverID = e.target.value;

    setSelectedDrivers((prevSelectedDrivers) => {
      if (preselectedId && driverID === preselectedId) {
        return prevSelectedDrivers.includes(driverID)
          ? prevSelectedDrivers.filter((id) => id !== driverID)
          : [...prevSelectedDrivers, driverID];
      }
      return e.target.checked
        ? [...prevSelectedDrivers, driverID]
        : prevSelectedDrivers.filter((id) => id !== driverID);
    });
  };

  const handleCreateNewDriver = useCallback(() => {
    showModal(
      <CreateNewDriverModal
        hardwareId={hardwareId}
        afterObjectCreationSave={(createdDriver) => {
          showModal(
            <SelectDriversToAddModal
              hardwareId={hardwareId}
              preselectedId={createdDriver.id}
              onSubmit={async (integrations) => {
                await addChild({
                  variables: {
                    input: integrations.map(({ parentId, objectId }) => ({
                      parentId,
                      objectId,
                    })),
                  },
                });
                closeModal();
              }}
            />,
            { isMegaModal: true, showCloseIcon: false },
          );
          openViewObjectCardDrawer({ modularObjectId: hardwareId });
        }}
      />,
      { isMegaModal: true, showCloseIcon: false },
    );
  }, [addChild, closeModal, hardwareId, openViewObjectCardDrawer, showModal]);

  return (
    <MegaModal className='w-screen gap-[24px] h-[80vh]'>
      <MegaModalHeader title='Select Drivers to Add' />
      <div className='flex flex-col min-h-0 grow'>
        <MegaModalSearchInput
          ref={inputRef}
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
        {loading && (
          <div className='flex flex-col gap-2 mt-[24px]'>
            <div className='text-xs tracking-widest text-gray-400 uppercase'>
              Results
            </div>
            <MegaModalEmptyList
              isLoading={loading}
              message='Please wait while we load your drivers.'
              showButton={false}
            />
          </div>
        )}
        {!loading && !filteredDrivers.length && (
          <div className='flex flex-col gap-2 mt-[24px]'>
            <div className='text-xs tracking-widest text-gray-400 uppercase'>
              Results
            </div>
            <MegaModalEmptyList
              message='No drivers match your search.'
              showButton={false}
            />
          </div>
        )}
        {!loading && filteredDrivers.length && (
          <SelectDriversContainer
            handleSelect={handleSelect}
            drivers={filteredDrivers}
            selectedDrivers={selectedDrivers}
            setSelectedDrivers={setSelectedDrivers}
          />
        )}
        {/* Simply for infinite loading */}
        <div ref={sentryRef} />
      </div>
      <div className='flex gap-8'>
        <Button
          figmaProps={{
            style: 'outline',
            size: 'small',
          }}
          onClick={handleCreateNewDriver}
        >
          <span className='uppercase whitespace-nowrap truncate'>new driver</span>
        </Button>
        <Button
          figmaProps={{
            style: 'fill',
            size: 'small',
          }}
          onClick={handleAddToObject}
          disabled={!selectedDrivers.length}
        >
          <span className='uppercase whitespace-nowrap truncate'>add to build</span>
        </Button>
      </div>
    </MegaModal>
  );
}
