import { DriverPillColor, type DriverStatus as OldDriverStatus } from '@/models/driver.model';
import { THEME_COLORS } from '@/util/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, type IconDefinition } from '@fortawesome/sharp-regular-svg-icons';
import { faCircleCheck, faCircleHalfStroke } from '@fortawesome/sharp-solid-svg-icons';
import { type DriverStatus } from '../../../__generated__/types';

export interface DriverStatusIconProps {
  className?: string;
  color: DriverPillColor;
  status: DriverStatus | OldDriverStatus;
}

export default function DriverStatusIcon (
  { className = '!h-[16px]', status, color }: DriverStatusIconProps,
): JSX.Element {
  const DRIVER_STATUS_COLORS = {
    [DriverPillColor.Black]: 'black',
    [DriverPillColor.White]: 'white',
    [DriverPillColor.Purple]: THEME_COLORS.primary.DEFAULT,
    [DriverPillColor.Teal]: THEME_COLORS.tertiary.DEFAULT,
    [DriverPillColor.Gold]: THEME_COLORS.secondary.DEFAULT,
  };

  // This is a workaround for the fact that the status is not the same as the DriverStatus enum and keeps it backwards compatible
  const statusText = status?.split(' ').join('_').toUpperCase();

  const DRIVER_STATUS_ICON: { [key in DriverStatus]: IconDefinition } = {
    IN_PROGRESS: faCircleHalfStroke,
    COMPLETE: faCircleCheck,
    WAIVED: faCircleCheck,
    TO_DO: faCircle,
  };

  return (
    <FontAwesomeIcon
      icon={DRIVER_STATUS_ICON[statusText] ?? faCircle}
      color={DRIVER_STATUS_COLORS[color]}
      className={className}
    />
  );
}
