import type { DiffInput, DriverVisibility, ModularObject } from '@/__generated__/types';
import { ConfirmationModal } from '@/components/modals';
import AddToParentModal from '@/components/modals/AddToParentModal/AddToParentModal';
import {
  useUpdateModularObjectMutation,
} from '@/components/modals/CollaboratorDiscovery/mutations/updateModularObject.generated';
import RemoveCollaboratorsModal from '@/components/modals/RemoveCollaborators/RemoveCollaboratorsModal';
import SelectBoxButton from '@/components/modules/SelectBoxInput/SelectBoxButton';
import SelectBoxOptions from '@/components/modules/SelectBoxInput/SelectBoxOptions';
import { GetAllDriversDocument } from '@/components/Schedule/queries/getAllDrivers.generated';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { GetWorkloadDocument } from '@/components/Schedule/Timeline/Gantt/getWorkload.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useDuplicateModularObjectTreeMutation } from '@/graphql/modularObject/duplicateModularObjectTree.generated';
import { useModal } from '@/hooks/useModal';
import { useApolloClient } from '@apollo/client';
import { Listbox } from '@headlessui/react';
import cx from 'classnames';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { useListActionContext } from '../ListActions.context';
import AddCollaboratorsModal from './AddCollaborators/AddCollaboratorsModal';
import { useAddObjectsToParentMutation } from './ConfirmationMessage/addObjectsToParent.generated';
import { useDuplicateModularObjectsMutation } from './ConfirmationMessage/duplcateModularObject.generated';
import { ListAction } from './constants';
import ListActionModal from './ListActionModal';
import { getUpdateAssigneePayload } from './UpdateAssignee/getUpdateAssigneePayload';
import UpdateAssignee from './UpdateAssignee/UpdateAssignee';
import UpdateResourceCostModal from './UpdateResourceCost/UpdateResourceCostModal';

export default function ListActions (): JSX.Element {
  const {
    selectedObjects,
    selectedAction,
    setSelectedAction,
    setDefaultSelectedObjects,
    clearSelectedObjects,
    listActions,
  } = useListActionContext();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [popperRefElement, setPopperRefElement] = useState(null);
  const { styles, attributes } = usePopper(popperRefElement, popperElement);
  const { showModal, closeModal } = useModal();
  const [addObjectsToParent] = useAddObjectsToParentMutation();
  const { reFetchObservableQueries } = useApolloClient();

  // Remove the dumb x transform that is added to the popper styles when the object card is open and we're viewing the object list actions for the gantt
  // Definitely a hack but I would consider removing the popper dependency in this component
  const popperStylesOverride = {
    ...styles.popper,
    transform: 'translate(0px, -32px)', // 32px is the height of the SelectBoxButton
  };

  const selectedTypes = Object.entries(selectedObjects)
    .map(([type, objects]) => {
      if (Object.keys(objects).length > 0) {
        return type;
      }
      return null;
    })
    .filter(Boolean);

  const hasSelectedItems = selectedTypes.length > 0;
  const numSelectedItems = Object.values(selectedObjects).reduce((acc, curr) => acc + Object.keys(curr).length, 0);

  const options = Object.entries(listActions).map(([action, supportedTypes]) => {
    const isNotSupported = selectedTypes.some((type) => !supportedTypes[type]);
    return {
      value: action,
      label: action,
      disabled: isNotSupported,
    };
  });

  const handleApplyAction = (): void => {
    switch (selectedAction) {
      case ListAction.UPDATE_HOURS:
      case ListAction.UPDATE_POINTS: {
        // Handle updating resource cost
        const handleCloseResourceCostModal = (): void => {
          setDefaultSelectedObjects();
          clearSelectedObjects();
          closeModal();
        };

        const selectedItems = Object.values(selectedObjects).reduce(
          (acc, curr) => [...acc, ...Object.values(curr).map(({ id }) => id)],
          [],
        );

        showModal(
          <UpdateResourceCostModal
            selectedItems={selectedItems}
            closeModal={handleCloseResourceCostModal}
          />,
          {
            className: 'max-w-[400px!important]',
          },
        );
        break;
      }
      case ListAction.ADD_COLLABORATORS: {
        const handleCloseAddCollaboratorsModal = (): void => {
          setDefaultSelectedObjects();
          clearSelectedObjects();
          closeModal();
        };

        const selectedItems = Object.values(selectedObjects).reduce(
          (acc, curr) => [...acc, ...Object.values(curr).map(({ id }) => id)],
          [],
        );

        showModal(
          <AddCollaboratorsModal
            selectedObjectIds={selectedItems}
            closeModal={handleCloseAddCollaboratorsModal}
          />,
          {
            className: 'w-[500px] !overflow-y-visible',
            handleClose: handleCloseAddCollaboratorsModal,
          },
        );
        break;
      }
      case ListAction.REMOVE_COLLABORATORS: {
        const handleCloseRemoveCollaboratorsModal = (): void => {
          setDefaultSelectedObjects();
          clearSelectedObjects();
          closeModal();
        };
        const selectedObjectIds = Object.values(selectedObjects).reduce(
          (acc, curr) => [...acc, ...Object.values(curr).map(({ id }) => id)],
          [],
        );
        showModal(
          <RemoveCollaboratorsModal
            closeModal={handleCloseRemoveCollaboratorsModal}
            selectedObjectIds={selectedObjectIds}
          />,
          {
            showCloseIcon: false,
            className: 'w-[448px] !max-w-[448px]',
          },
        );
        break;
      }
      case ListAction.UPDATE_TO_PRIVATE_VISIBILITY: {
        void confirmVisibilityToggle('PRIVATE');
        break;
      }
      case ListAction.UPDATE_TO_COMPANY_VISIBILITY: {
        void confirmVisibilityToggle('COMPANY');
        break;
      }
      case ListAction.DUPLICATE:
        confirmDuplication();
        break;
      case ListAction.DUPLICATE_TREE:
        confirmTreeDuplication();
        break;
      case ListAction.ADD_TO_PARENT: {
        const objects = [
          ...Object.values(selectedObjects.builds),
          ...Object.values(selectedObjects.drivers),
        ];

        const objectIds = objects.map(({ id }) => id);
        const handleAddToParent = async (parentId: string) => {
          await addObjectsToParent({
            variables: {
              objectIds,
              parentId,
            },
            onCompleted: () => {
              addToastSuccess('Successfully added items to parent');
              clearSelectedObjects();
            },
            onError: () => {
              addToastError('Failed to add items to parent');
            },
          });

          void reFetchObservableQueries();
          closeModal();
        };

        showModal(
          <AddToParentModal modularObjects={objects} onSubmit={handleAddToParent} />,
          {
            // className: 'max-w-[80vw]',
            isMegaModal: true,
            showCloseIcon: false,
          },
        );
        break;
      }
      case ListAction.ENABLE_APPROVALS:
        void confirmApprovalsToggle(true);
        break;
      case ListAction.DISABLE_APPROVALS:
        void confirmApprovalsToggle(false);
        break;
      case ListAction.UPDATE_ASSIGNEE: {
        const handleUpdateAssignee = async (assigneeId: string) => {
          const driverIds = Object.values(selectedObjects.drivers || []).map(({ id }) => id);
          const payload = getUpdateAssigneePayload(assigneeId, driverIds);

          await updateModularObject({
            variables: {
              input: payload,
            },
            onCompleted: () => {
              closeModal();
              addToastSuccess('Successfully updated assignee(s)');
              clearSelectedObjects();
            },
            onError: () => {
              closeModal();
              addToastError('Failed to update assignee(s)');
            },
          });
        };
        showModal(
          <UpdateAssignee handleUpdateAssignee={handleUpdateAssignee} />,
          {
            className: 'w-[448px] !max-w-[448px] !overflow-y-visible',
          },
        );
        break;
      }
      default:
        setIsConfirmationOpen(true);
    }
  };

  const handleActionChange = (value: string): void => {
    setSelectedAction(value);
  };

  const [updateModularObject] = useUpdateModularObjectMutation();
  const [duplicateObjects] = useDuplicateModularObjectsMutation();
  const [duplicateTree] = useDuplicateModularObjectTreeMutation();

  const confirmApprovalsToggle = async (enable: boolean): Promise<Promise<void>> => {
    const getConfirmationMessage = () => {
      return `Approvals will be ${enable ? 'enabled' : 'disabled'} for the selected items.`;
    };

    const handleToggleApprovals = async () => {
      const approvalMapFunc = ({ id }: ModularObject): DiffInput => ({
        externalID: id,
        externalType: 'modular_object',
        fromMigration: false,
        diffType: 'updated',
        diff: {
          displayNames: {
            approvalsEnabled: 'Approvals Enabled',
          },
          to: {
            approvalsEnabled: enable,
          },
        },
      });

      // Form diffs for updating all selected drivers with new resource cost
      const diffs: DiffInput[] = [
        ...Object.values(selectedObjects.drivers || []).map(approvalMapFunc),
        ...Object.values(selectedObjects.builds || []).map(approvalMapFunc),
      ];

      await updateModularObject({
        variables: {
          input: {
            diffs,
          },
        },
        refetchQueries: [GetAllDriversDocument, GetWorkloadDocument, GetTopLevelModularObjectsDocument],
        onCompleted: () => {
          addToastSuccess(`Successfully turned approvals ${enable ? 'on' : 'off'} for items`);
          clearSelectedObjects();
        },
        onError: (error) => {
          addToastError(error.message);
        },
      });

      closeModal();
    };

    showModal(
      <ConfirmationModal
        onConfirm={handleToggleApprovals}
        onClose={closeModal}
        header='Are you sure?'
        message={getConfirmationMessage()}
        confirmText={`Yes, turn approvals ${enable ? 'on' : 'off'} for these items`}
        cancelText='No'
      />,
      {
        className: '!w-[500px]',
      },
    );
  };

  const confirmVisibilityToggle = async (visibility: DriverVisibility): Promise<Promise<void>> => {
    const getConfirmationMessage = () => {
      return `Visibility will be updated to ${visibility.toLowerCase()} for the selected items.`;
    };

    const handleUpdateVisibility = async () => {
      const visibilityMapFunc = ({ id }: ModularObject): DiffInput => ({
        externalID: id,
        externalType: 'modular_object',
        fromMigration: false,
        diffType: 'updated',
        diff: {
          displayNames: {
            visibility: 'Visibility',
          },
          to: {
            visibility: visibility.charAt(0).toUpperCase() + visibility.slice(1).toLowerCase(),
          },
        },
      });

      // Form diffs for updating all selected drivers with new visibility
      const diffs: DiffInput[] = [
        ...Object.values(selectedObjects.drivers || []).map(visibilityMapFunc),
        ...Object.values(selectedObjects.builds || []).map(visibilityMapFunc),
      ];

      await updateModularObject({
        variables: {
          input: {
            diffs,
          },
        },
        refetchQueries: [GetAllDriversDocument, GetWorkloadDocument, GetTopLevelModularObjectsDocument],
        onCompleted: () => {
          clearSelectedObjects();
          addToastSuccess(`Successfully updated visibility to ${visibility.toLowerCase()} for items`);
        },
        onError: (error) => {
          addToastError(error.message);
        },
      });

      closeModal();
    };

    showModal(
      <ConfirmationModal
        onConfirm={handleUpdateVisibility}
        onClose={closeModal}
        header='Are you sure?'
        message={getConfirmationMessage()}
        confirmText={`Yes, update visibility to ${visibility.toLowerCase()} for these items`}
        cancelText='No'
      />,
      {
        className: '!w-[500px]',
      },
    );
  };

  const confirmDuplication = () => {
    const numTasks = Object.values(selectedObjects.drivers).length;
    const numObjects = Object.values(selectedObjects.builds).length;

    const getConfirmationMessage = () => {
      if (numTasks > 0 && numObjects > 0) {
        return `${numTasks} drivers and ${numObjects} builds will be duplicated.`;
      } else if (numTasks > 0) {
        return `${numTasks} drivers will be duplicated.`;
      } else if (numObjects > 0) {
        return `${numObjects} builds will be duplicated.`;
      }
    };

    const handleDuplicateObjects = async () => {
      const driverIDs = Object.values(selectedObjects.drivers).map(({ id }) => id);
      const buildIDs = Object.values(selectedObjects.builds).map(({ id }) => id);

      await duplicateObjects({
        variables: {
          ids: [...driverIDs, ...buildIDs],
        },
        refetchQueries: [GetAllDriversDocument, GetWorkloadDocument, GetTopLevelModularObjectsDocument],
        onCompleted: () => {
          addToastSuccess('Successfully duplicated items');
          clearSelectedObjects();
        },
        onError: () => {
          addToastError('Failed to duplicate items');
        },
      });

      closeModal();
    };

    showModal(
      <ConfirmationModal
        onConfirm={handleDuplicateObjects}
        onClose={closeModal}
        header='Are you sure?'
        message={getConfirmationMessage()}
        confirmText='Yes, duplicate these items'
        cancelText='No'
      />,
      {
        className: '!w-[500px]',
      },
    );
  };

  const confirmTreeDuplication = () => {
    const numDrivers = Object.values(selectedObjects.drivers).length;
    const numBuilds = Object.values(selectedObjects.builds).length;
    const totalItems = numDrivers + numBuilds;

    const getConfirmationMessage = () => {
      if (totalItems > 0) {
        const lf = new Intl.ListFormat('en');
        return `The entire program with ${
          lf.format(Object.values(selectedObjects.builds).map(({ name }) => name))
        } at the top-level will be duplicated.`;
      }
    };

    const handleDuplicateObjects = async () => {
      const driverIDs = Object.values(selectedObjects.drivers).map(({ id }) => id);
      const buildIDs = Object.values(selectedObjects.builds).map(({ id }) => id);

      await duplicateTree({
        variables: {
          rootIds: [...driverIDs, ...buildIDs],
        },
        refetchQueries: [GetAllDriversDocument, GetWorkloadDocument, GetTopLevelModularObjectsDocument],
        onCompleted: () => {
          addToastSuccess(`Successfully duplicated the ${totalItems === 1 ? 'program' : 'programs'}`);
          clearSelectedObjects();
        },
        onError: () => {
          addToastError(`Failed to duplicate the ${totalItems === 1 ? 'program' : 'programs'}`);
        },
      });

      closeModal();
    };

    showModal(
      <ConfirmationModal
        onConfirm={handleDuplicateObjects}
        onClose={closeModal}
        header='Are you sure?'
        message={getConfirmationMessage()}
        confirmText='Duplicate'
        cancelText='Cancel'
      />,
      {
        className: '!w-[500px]',
      },
    );
  };

  return (
    <>
      <div
        className={cx(
          'absolute z-20 bottom-0 items-center justify-center left-0 right-0 flex p-7 transition-all pointer-events-none',
          {
            'opacity-0 -bottom-10': !hasSelectedItems,
            'opacity-100 bottom-0': hasSelectedItems,
          },
        )}
        data-testid='task-list-actions'
      >
        <div
          className={cx(
            'min-w-0 2xl:min-w-[400px] shadow-md p-5 border rounded-md font-medium flex 2xl:flex-row flex-col justify-between 2xl:items-center gap-3 bg-black',
            {
              'pointer-events-auto bottom-0': hasSelectedItems,
              'pointer-events-none': !hasSelectedItems,
            },
          )}
        >
          <div className='flex gap-2 items-center'>
            <div className='items-center text-lg truncate text-white/95'>
              {numSelectedItems} selected
            </div>
            <button
              type='button'
              onClick={clearSelectedObjects}
              className='flex gap-2 items-center group text-white/70 w-[50px]'
            >
              <i className='text-[16px] leading-[16px] fa-sharp fa-solid fa-times' />
              <div className='hidden opacity-0 transition-all group-hover:flex group-hover:opacity-100'>
                Clear
              </div>
            </button>
          </div>
          <div className='flex flex-col gap-3 lg:flex-row lg:items-center'>
            <div className='relative dark'>
              <Listbox
                value={selectedAction}
                onChange={handleActionChange}
              >
                <SelectBoxButton
                  placeholder='Select an action'
                  selectedItem={selectedAction}
                  ref={setPopperRefElement}
                  buttonClassName='w-[250px]'
                />
                <SelectBoxOptions
                  options={options}
                  ref={setPopperElement}
                  style={popperStylesOverride}
                  {...attributes.popper}
                />
              </Listbox>
            </div>
            <button
              type='button'
              onClick={handleApplyAction}
              className='button button-primary'
              disabled={!selectedAction}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
      <ListActionModal confirmationOpen={isConfirmationOpen} setConfirmationOpen={setIsConfirmationOpen} />
    </>
  );
}
