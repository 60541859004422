import { type ModuleType } from '@/components/modules/utils/module.constants';

export interface Template {
  id?: string;
  version?: number;
  type?: TemplateType;
  name?: string;
  description?: string;
  imagePath?: string;
  pageColumns?: string[];
  modifiedAt?: string;
  deletedAt?: string;
  createdAt?: string;
  createdById?: string;
  ownerId?: string;
  isIntegratable?: boolean;
  modules?: Record<string, Module>;
  visibility?: string;
  subType?: TemplateSubType;
}

export interface Module {
  name?: string;
  module?: Record<string, Module>;
  type?: ModuleType;
  displayName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: Record<string, any>;
  modules?: Record<string, Module>;
  value?: string;
  valueId?: string;
  showInList?: boolean;
  order?: number;
}

export enum TemplateType {
  Build = 'build',
  Driver = 'driver',
  Program = 'program',
}

export enum TemplateSubType {
  Milestone = 'milestone',
  Requirement = 'reqver',
  Task = 'task',
}
