import type { ModularObject } from '@/__generated__/types';
import type { DriverFragment } from '@/components/Schedule/queries/driverFragment.generated';
import SelectDriver from './SelectDriver';

interface SelectDriversProps {
  handleSelect: (selected: string[]) => void;
  tableRef: React.RefObject<HTMLDivElement>;
  drivers: DriverFragment[];
  selectedDrivers: string[];
}
export default function SelectDrivers ({
  handleSelect,
  drivers,
  tableRef,
  selectedDrivers,
}: Readonly<SelectDriversProps>): JSX.Element {
  return (
    <div className='overflow-auto' ref={tableRef}>
      {drivers
        .map((driver: ModularObject & { Status: string } & { selected: boolean }) => (
          <SelectDriver key={driver.id} driver={driver} onChange={handleSelect} selectedDrivers={selectedDrivers} />
        ))}
    </div>
  );
}
