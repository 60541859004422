import type { DriverMap, MappableDriverField } from '@/components/flows/UploadCsvUserFlow/steps/MapHeader/constants';
import { isValidDriverStatus } from '@/models/driver.model';
import type { TaskError } from '../../../types';
import { isValidDriverType } from '../../MapHeader/utils';

export default function getRowErrors (
  row: DriverMap,
  driverKeyMap: Record<MappableDriverField, string>,
  emails: string[] = [],
): Record<string, TaskError> {
  const rowErrors = {};

  const driverType = row[driverKeyMap?.type];
  const subDriverType = row[driverKeyMap?.subDriverType];
  const driverStatus = row[driverKeyMap?.status];
  const driverCollaboratorEmails = row[driverKeyMap?.collaboratorEmail];
  const driverCollaboratorRoles = row[driverKeyMap?.collaboratorRole];
  const driverAssignee = row[driverKeyMap?.assignee]?.toLowerCase().trim();

  if (!row[driverKeyMap?.name]) {
    rowErrors[driverKeyMap.name] = { column: 'name', error: `Missing ${driverKeyMap.name}` };
  }

  if (driverStatus && !isValidDriverStatus(driverStatus)) {
    rowErrors[driverKeyMap.status] = { column: 'status', error: `Invalid ${driverKeyMap.status} (${driverStatus})` };
  }

  if (!subDriverType && driverType && !isValidDriverType(driverType)) {
    rowErrors[driverKeyMap.type] = {
      column: 'type',
      error: `Invalid ${driverKeyMap.type} "${driverType}" will be defaulted to "Task"`,
    };
  }

  if (subDriverType && !isValidDriverType(subDriverType)) {
    rowErrors[driverKeyMap.subDriverType] = {
      column: 'subtaskType',
      error: `Invalid ${driverKeyMap.subDriverType} "${subDriverType}" will be defaulted to "Task"`,
    };
  }

  if (driverCollaboratorEmails) {
    const collaboratorEmails = driverCollaboratorEmails?.split(',').map(email => email.toLowerCase().trim());
    const invalidEmails = collaboratorEmails?.filter(email => !emails.includes(email));
    if (invalidEmails.length) {
      rowErrors[driverKeyMap?.collaboratorEmail] = {
        column: 'collaboratorEmail',
        error: `Collaborator (${invalidEmails.join(', ')}) email not found`,
      };
    }
  }

  if (driverAssignee && !emails.includes(driverAssignee)) {
    rowErrors[driverKeyMap.assignee] = { column: 'assignee', error: `Assignee (${driverAssignee}) email not found` };
  }

  if (
    (driverAssignee && !driverCollaboratorEmails) ||
    (driverAssignee && !driverCollaboratorEmails?.includes(driverAssignee))
  ) {
    rowErrors[driverKeyMap.collaboratorEmail] = {
      column: 'collaboratorEmail',
      error: `Assignee (${driverAssignee}) needs to also exist in collaborator column`,
    };
  }

  if (driverCollaboratorRoles?.split(',').length !== driverCollaboratorEmails?.split(',').length) {
    rowErrors[driverKeyMap.collaboratorRole] = {
      column: 'collaboratorRole',
      error: 'Collaborator roles do not match the number of collaborator emails',
    };
  }

  return rowErrors;
}
