import type { Dependency } from '@/__generated__/types';
import { useEditContext } from '@/components';
import { ModalContext } from '@/components/modals';
import DependencyModal from '@/components/modals/DependencyModal/DependencyModal';
import {
  useGetModularObjectDependenciesByIdQuery,
} from '@/components/modals/DependencyModal/getModularObjectDependenciesById.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { TemplateType } from '@/models/template.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import cx from 'classnames';
import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DependencyCard } from './DependencyCard';

interface AddDependencyButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  buttonText: string;
}

function AddDependencyButton ({ onClick, buttonText }: AddDependencyButtonProps) {
  return (
    <button onClick={onClick} className='btn-ghost tracking-[1.5px] w-[140px] h-[36px]'>
      {buttonText}
    </button>
  );
}

function DependencyList (
  { objectName, title, children, ...rest }: { objectName: string; title: string; children: React.ReactNode },
) {
  return (
    <div className='h-full' {...rest}>
      <div className='flex gap-1 py-2 pl-8 font-bold bg-white truncate'>
        <span className='max-w-[300px] truncate'>{objectName}</span>
        <div>{title}</div>
      </div>
      <div className='grid grid-cols-1 gap-3 justify-items-center pt-[24px]'>
        {children}
      </div>
    </div>
  );
}

function DependencyDateWarning () {
  return (
    <div className='flex justify-center items-center w-full text-gray-ghost h-[156px]'>
      <i className='mr-2 fa-sharp fa-regular fa-triangle-exclamation' />
      <p>
        Dependencies cannot be utilized until this item has dates. Add a start date, target date, or drivers to enable.
      </p>
    </div>
  );
}

export default function DependencySection (
  { objectId, templateId, templateName }: { objectId: string; templateId: string; templateName: string },
): JSX.Element {
  const { getValues } = useFormContext();
  const { showModal } = useContext(ModalContext);
  const { canUserEdit } = useEditContext();
  const { objectCardData } = useObjectCardContext();

  const isDriver = objectCardData?.template?.type === TemplateType.Driver;

  const loggedInUser = useLoggedInUser();
  const { data, loading: isLoadingDependencies, error } = useGetModularObjectDependenciesByIdQuery({
    variables: {
      id: objectId,
    },
    skip: !objectId,
  });

  const {
    blockedBy = [],
    blocking = [],
  } = data?.getModularObjectByID ?? {};

  const [
    targetDate,
    startDate,
  ] = getValues(['startDate', 'targetDate']);

  const objectName = useWatch({ name: 'name' });

  if (isLoadingDependencies || error) return null;

  return targetDate || startDate ?
    (
      <div className='grid grid-cols-2 bg-zinc-100 pb-[24px]'>
        <DependencyList objectName={objectName} title='is blocked by' data-testid='blocked by list'>
          {blockedBy?.map(dep => (
            <DependencyCard
              key={dep.id}
              dependencyName={dep?.blockedBy?.name}
              isCollaboratorObject={loggedInUser?.organizationId !== dep?.blockedBy?.owner?.organizationId}
              isDependencyLocked={dep?.blockedBy?.startDateLocked || dep?.blockedBy?.targetDateLocked}
              dependency={dep}
              iconName={dep?.blockedBy?.template?.name}
              onClick={() => {
                if (dep.hasPendingApproval) return;
                showModal(
                  <DependencyModal
                    currentObjectId={objectId}
                    templateId={templateId}
                    templateName={templateName}
                    dependencyIdToEdit={{
                      id: dep.id,
                      daysToEdit: dep.gapDays,
                      monthsToEdit: dep.gapMonths,
                      yearsToEdit: dep.gapYears,
                      blockTypeToEdit: dep.blockType,
                    }}
                    initialStep={2}
                  />,
                  {
                    isMegaModal: true,
                  },
                );
              }}
            />
          ))}
          {canUserEdit && (
            <div
              className={cx('border-r h-[36px] w-full grid justify-items-center mt-[32px] ', {
                'w-auto border-none h-auto': blockedBy?.length || blocking?.length,
              })}
            >
              <AddDependencyButton
                buttonText='Add Blocked By'
                onClick={(e) => {
                  e.preventDefault();
                  showModal(
                    <DependencyModal
                      currentObjectId={objectId}
                      templateId={templateId}
                      templateName={templateName}
                      blockedBy={blockedBy as Dependency[]}
                      blocking={blocking as Dependency[]}
                    />,
                    {
                      isMegaModal: true,
                    },
                  );
                }}
              />
            </div>
          )}
        </DependencyList>
        <DependencyList
          objectName={objectName}
          title='is blocking'
          data-testid='blocking list'
        >
          {blocking?.map(dep => (
            <DependencyCard
              key={dep.id}
              dependency={dep}
              dependencyName={dep?.modularObject?.name}
              isCollaboratorObject={loggedInUser?.organizationId !== dep?.modularObject?.owner?.organizationId}
              isDependencyLocked={dep?.modularObject?.startDateLocked || dep?.modularObject?.targetDateLocked}
              iconName={dep?.modularObject?.template?.name}
              onClick={() => {
                if (dep.hasPendingApproval) return;
                showModal(
                  <DependencyModal
                    currentObjectId={objectId}
                    templateId={templateId}
                    templateName={templateName}
                    dependencyIdToEdit={{
                      id: dep.id,
                      daysToEdit: dep.gapDays,
                      monthsToEdit: dep.gapMonths,
                      yearsToEdit: dep.gapYears,
                      blockTypeToEdit: dep.blockType,
                    }}
                    initialStep={2}
                  />,
                  {
                    isMegaModal: true,
                  },
                );
              }}
            />
          ))}
          {canUserEdit && (
            <div className='pt-[32px]'>
              <AddDependencyButton
                buttonText='Add Blocking'
                onClick={(e) => {
                  e.preventDefault();
                  showModal(
                    <DependencyModal
                      currentObjectId={objectId}
                      templateId={templateId}
                      templateName={templateName}
                      blocking={blocking as Dependency[]}
                      blockedBy={blockedBy as Dependency[]}
                      isBlockingDependency
                    />,
                    {
                      isMegaModal: true,
                    },
                  );
                }}
              />
            </div>
          )}
        </DependencyList>
        {canUserEdit && (
          <p
            className={cx('col-span-2 text-gray-ghost mx-auto mt-[32px] whitespace-nowrap', {
              'hidden': blockedBy?.length || blocking?.length,
            })}
          >
            <i className='mr-2 fa-sharp fa-regular fa-triangle-exclamation' />
            Changes to this{' '}
            {isDriver ? 'driver' : 'build'}&apos;s dates via dependencies will not need approvals, although
            notifications will be sent.
          </p>
        )}
      </div>
    ) :
    <DependencyDateWarning />;
}
