import { type Approval } from '@/__generated__/types';
import { Label } from '@/components/form';
import CaretIcon from '@/components/Icons/CaretIcon';
import User from '@/components/User/User';
import cx from 'classnames';
import { useState } from 'react';
import ApprovalChanges from './ApprovalChanges';
import ApproveButton from './ApproveButton';
import DenyButton from './DenyButton';
import { getDateDisplay } from './utils';
import { useObjectCardContext } from '@/state/ObjectCard.context';

export interface ApprovalRowProps {
  className?: string;
  approval: Approval;
  onApprove: () => Promise<void>;
  onDeny: () => Promise<void>;
  isSendingApproval: boolean;
}

export const CHANGE_LOG_LIMIT = 2;

export default function ApprovalRow ({
  className,
  approval,
  onApprove,
  onDeny,
  isSendingApproval,
}: Readonly<ApprovalRowProps>): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  const { requestedAt, requestedBy } = approval;

  const requestedChanges = approval.requestedChanges || [];

  const toggleOverflow = (): void => {
    setIsExpanded(!isExpanded);
  };

  const { openViewObjectCardDrawer } = useObjectCardContext();

  const openApprovalReference = (): void => {
    if (approval.approvalType === 'diff') {
      openViewObjectCardDrawer({ modularObjectId: approval.referenceId });
    }
  };

  return (
    <div
      id={`approval-${approval.id}`}
      data-testid='approval-table-row'
      className={cx('transition-all hover:bg-zinc-100 bg-white shadow-[0_1px_0px_0px_#E6E6E6] px-[32px]', className)}
    >
      <div className='flex items-center w-full min-h-[72px]'>
        {/* Requested on */}
        <div className='flex items-center w-[18%]'>
          <div className='font-medium'>
            {getDateDisplay(requestedAt)}
          </div>
        </div>
        {/* Requested by */}
        <div className='flex items-center w-[20%]'>
          <User user={requestedBy} />
        </div>
        {/* Item */}
        <div className='flex flex-col justify-center w-[18%]'>
          <div
            className={cx({
              'text-primary cursor-pointer': approval?.referenceName !== 'Not visible to you',
              'opacity-50': approval?.referenceName === 'Not visible to you',
            })}
            onClick={openApprovalReference}
          >
            {approval?.referenceName || '—'}
          </div>
          <div className='first-letter:uppercase'>{approval?.referenceType}</div>
        </div>
        {/* Parent name */}
        <div className='flex flex-col justify-center w-[18%]'>
          {/* <div className='first-letter:uppercase'>{getParentName(diff) === 'Unknown' ? '' : getParentName(diff)}</div> */}
          <div className='first-letter:uppercase'>{approval?.referenceParentName}</div>
        </div>
        <div className='w-[8%] opacity-50'>
          {requestedChanges.length} {requestedChanges.length === 1 ? 'change' : 'changes'}
        </div>
        <div className='w-[18%] pr-5 text-right'>
          <button className='items-center font-bold transition-all text-primary' onClick={toggleOverflow}>
            {isExpanded ? 'Hide' : 'Show'} Details
            <CaretIcon
              className={cx('fill-primary inline-block ml-2 transition-transform', { 'rotate-180': isExpanded })}
            />
          </button>
        </div>
      </div>
      <div
        className={cx('px-5 transition-all duration-300', {
          'h-0 opacity-20 overflow-hidden': !isExpanded,
          'py-3 opacity-100': isExpanded,
        })}
      >
        <Label className='mb-0'>Requested Changes</Label>
        <div className='flex justify-between w-full'>
          {/* Changes */}
          <div data-testid='approval-changes' className={cx('w-9/12 py-4 flex flex-col gap-y-2')}>
            <ApprovalChanges changes={requestedChanges} />
          </div>
          {/* Actions */}
          <div className='flex gap-8 items-start'>
            <div className='flex justify-end items-center py-3'>
              <DenyButton
                isSendingApproval={isSendingApproval}
                handleSubmitApproval={onDeny}
              />
            </div>
            <div data-testid='table-row-cell' className='flex justify-end items-center'>
              <ApproveButton handleSubmitApproval={onApprove} isSendingApproval={isSendingApproval} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
