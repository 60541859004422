import type { User } from '@/__generated__/types';
import { Drawer } from '@/components/drawers/Drawer';
import { Loader } from '@/components/loader';
import { addToastSuccess } from '@/components/Toast/utils';
import Color from '@/components/types/Color.enum';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useQueryParams, type UserProfileQueryParams } from '@/hooks/useQueryParams';
import { useUserProfileDrawer } from '@/hooks/useUserProfileDrawer';
import { ADMIN_ROLE, MANAGER_ROLE, SUPER_ROLE } from '@/util/constants';
import { putUser } from '@/util/requests.functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEnvelope, faLinkSimple, faUserPen } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { Input } from '../../form/Input';
import { ProfileImage } from '../../ProfileImage/ProfileImage';
import { useGetUserByIdQuery } from '../getUserById.generated';
import UserProfileTeams from './UserProfileTeams';

export default function UserProfileDrawer () {
  const [workCapacity, setWorkCapacity] = useState<number>();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { queryParams } = useQueryParams<UserProfileQueryParams>();
  const { closeUserProfileDrawer, isUserProfileDrawerOpen } = useUserProfileDrawer();

  const userId = queryParams.activeProfileId;
  const { refetch: refetchUser, data: userData } = useGetUserByIdQuery({
    onCompleted: (data) => {
      setWorkCapacity(data.getUserByID?.workCapacity);
    },
    variables: { id: userId },
    skip: !userId,
  });
  const user = userData?.getUserByID;
  const loggedInUser = useLoggedInUser();
  const isInternal = user?.organization?.id === loggedInUser?.organizationId
    || user?.organizationId === loggedInUser?.organizationId;
  const isDeleted = Boolean(user?.deletedAt);
  const canEditWorkCapacity = [ADMIN_ROLE, SUPER_ROLE, MANAGER_ROLE].includes(loggedInUser?.role) && isInternal;

  const handleSaveWorkCapacity = async (workCapacity) => {
    try {
      setIsSaving(true);
      const response = await putUser({ ...(user as User), workCapacity });
      setIsSaving(false);
      setSaveStatus('Updated!');
      setTimeout(() => {
        setSaveStatus('');
      }, 1500);
      setWorkCapacity(response.workCapacity);
      await refetchUser();
    } catch (err) {
      console.error(err);
      setIsSaving(false);
      setSaveStatus('Failed to update');
      setTimeout(() => {
        setSaveStatus('');
      }, 1500);
    }
  };

  const handleChange = async (e) => {
    const newValue = Number(e.target.value);
    if (!isNaN(newValue) && newValue !== user?.workCapacity) {
      setWorkCapacity(newValue);
      clearTimeout(typingTimeout);
      setTypingTimeout(setTimeout(async () => {
        await handleSaveWorkCapacity(newValue);
      }, 1000));
    }
  };

  const [, copy] = useCopyToClipboard();

  const copyEmail = async (e): Promise<void> => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await copy(user?.email);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  };

  const copyLink = () => {
    copy(window.location.href).then(() => {
      addToastSuccess('Link copied to clipboard');
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <Drawer
      isOpen={isUserProfileDrawerOpen}
      handleClose={closeUserProfileDrawer}
      anchor='right'
      position='right'
    >
      <section className='flex flex-col h-screen text-white bg-black gap-[32px] p-[32px] w-[343px]'>
        <div className='flex justify-end gap-[16px]'>
          <button
            type='button'
            onClick={copyLink}
            className='cursor-pointer'
          >
            <FontAwesomeIcon icon={faLinkSimple} />
          </button>
          <a href={`mailto:${user?.email}`}>
            <FontAwesomeIcon icon={faEnvelope} className='text-[14px] pr-[21px]' />
          </a>
        </div>
        <div className='flex gap-4 items-center'>
          <ProfileImage user={user as User} size='xl' />
          <div className='truncate'>
            <h1
              className={cx('effra-24 text-wrap truncate max-h-[64px]', {
                'text-primary': isInternal,
                'text-tertiary': !isInternal,
              })}
            >
              {user?.fullName}
              {isDeleted && <div className='text-gray-60 effra-12'>(Removed User)</div>}
            </h1>
            {Boolean(user?.title) && <p className='truncate effra-14 max-h-[32px] text-wrap'>{user?.title}</p>}
          </div>
        </div>
        <div className='flex flex-col gap-[32px]'>
          <div className='flex flex-col gap-[16px]'>
            <h2 className='effra-16'>Information</h2>
            <div className='flex flex-col gap-[4px] truncate'>
              <p className='label-inverted'>Company</p>
              <p className='effra-14 py-[8px] truncate'>{user?.organization?.name}</p>
            </div>
            <div className='flex flex-col gap-[4px]'>
              <p className='label-inverted'>Email</p>
              <button
                onClick={copyEmail}
                className='flex items-center text-left cursor-pointer effra-14 group gap-[8px] truncate'
              >
                <div className='truncate'>{user?.email}</div>
                <FontAwesomeIcon
                  icon={faCopy}
                  className='opacity-0 transition-opacity group-hover:opacity-100 text-[14px]'
                />
                <span
                  className={cx('effra-10 text-white transition-opacity duration-500', {
                    'opacity-100': copySuccess,
                    'opacity-0': !copySuccess,
                  })}
                >
                  Copied!
                </span>
              </button>
            </div>
          </div>
          <hr className='opacity-40' />
          {isInternal && !isDeleted && (
            <div className='flex flex-col gap-[16px]'>
              <h2 className='effra-16'>Collaboration</h2>
              <div className='flex flex-col gap-[8px]'>
                <UserProfileTeams user={user} />
                <p className='label-inverted'>Weekly Capacity</p>
                <div className='flex items-center gap-[4px] group/capacity'>
                  {canEditWorkCapacity ?
                    (
                      <>
                        <Input
                          type='number'
                          value={workCapacity ? String(workCapacity) : ''}
                          className={cx(
                            'p-1 bg-black effra-14 hover:border-b focus:border-b border-black hover:border-primary focus:border-primary focus:bg-white/15 ring-0 outline-none transition',
                            {
                              'w-[4ch]': Boolean(workCapacity),
                              'w-[6.5rem]': !workCapacity,
                            },
                          )}
                          containerClassName='!items-baseline'
                          placeholder={`Enter ${user?.organization?.resourceCostUnit}`}
                          onChange={handleChange}
                          onBlur={handleChange}
                          suffix={user?.organization?.resourceCostUnit}
                        />
                        <FontAwesomeIcon
                          icon={faUserPen}
                          className='opacity-0 transition-opacity text-[14px] group-hover/capacity:opacity-100'
                        />
                      </>
                    ) :
                    (
                      <p className='effra-14 py-[3px] pr-[8px]'>
                        {user?.workCapacity}{' '}
                        <span className='label-inverted'>{user?.organization?.resourceCostUnit}</span>
                      </p>
                    )}
                  {isSaving && <Loader className='w-5 h-5' color={Color.WHITE} />}
                  <span
                    className={cx('effra-10 text-white transition-opacity duration-500 flex gap-1', {
                      'opacity-100': saveStatus,
                      'opacity-0': !saveStatus,
                    })}
                  >
                    {saveStatus}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </Drawer>
  );
}
