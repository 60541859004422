import type { TaskErrors } from '../../types';

interface FieldErrorDisplayProps {
  errors: TaskErrors;
  driverKeyMap: Record<string, string>;
}

export default function FieldErrorDisplay ({ errors, driverKeyMap }: FieldErrorDisplayProps): JSX.Element {
  const types = new Set();
  Object.entries(errors ?? {}).forEach(([_, milestoneErrors]) => {
    milestoneErrors.forEach(({ column }) => {
      types.add(column);
    });
  });

  return Object.keys(errors)?.length ?
    (
      <div className='mt-2'>
        <div className='mt-4 font-normal text-[14px]'>
          <p>
            We were unable to correctly map data in the{' '}
            {Array.from(types).filter(type => type !== 'name').map((error: string, idx) => {
              return (
                <>
                  {idx >= 1 && ' and '}
                  <span key={`${error}-${idx}`} className='font-bold'>{driverKeyMap[error]}</span>
                </>
              );
            })} columns due to differences between our value options and the uploaded template.
          </p>
          <p className='mt-2'>
            {(types.has('status') || types.has('type')) && (
              <>
                The drivers with issues can still be created, but these fields will be set to defaults.<br />
              </>
            )}
            {types.has('name') && (
              <span className='italic'>Additionally, drivers missing a name field will not be created.</span>
            )}
          </p>
          <p className='mt-2'>
            If you’d like to fix this, close this modal or reset the process, update your template and try again, or you
            can manually create or edit drivers at any time.
          </p>
        </div>
        <div className='mt-8 w-full max-w-[500px]'>
          <div className='flex gap-5 pb-3 font-bold effra-xs text-black/40'>
            <div className='px-5 w-1/2'>driver name</div>
            <div className='w-1/2'>error(s)</div>
          </div>
          <div className='flex overflow-scroll flex-col gap-2 py-3 px-5 bg-white h-[98px]'>
            {Object.entries(errors).map(([name, errors], idx) => (
              <div key={`${name}-${idx}`} className='flex gap-5'>
                <div className='w-1/2 font-bold'>{name}</div>
                <div className='w-1/2 font-normal font-effra'>{errors.map(({ error }) => error).join(', ')}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    ) :
    null;
}
