import { useEditContext } from '@/components/cards';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import NumberInput from '@/components/modules/NumberInput';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { buildFieldsPendingApprovalMap } from '@/util/approvals';
import { faHourglassHalf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangle } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { debounce } from 'lodash';
import { type ChangeEvent, type FocusEvent, useCallback } from 'react';
import { buildUpdateObjectDiffPayload } from '../../utils';
import { useUpdateEstimationMutation } from './UpdateEstimation.generated';
import metrics from '@/util/metrics';

const ESTIMATION_FIELD_NAME = 'resourceCost';

export default function Estimation () {
  const loggedInUser = useLoggedInUser();
  const { canUserEdit } = useEditContext();
  const resourceCostUnit = loggedInUser?.organization?.resourceCostUnit ?? 'hours';
  const [updateEstimationMutation] = useUpdateEstimationMutation();
  const { objectCardData } = useObjectCardContext();

  const resourceCost = objectCardData.resourceCost;

  const updateEstimation = useCallback(async (newFieldValue: string) => {
    const updateEstimationPayload = buildUpdateObjectDiffPayload({
      objectId: objectCardData?.id,
      fieldName: ESTIMATION_FIELD_NAME,
      newFieldValue,
    });

    await updateEstimationMutation({
      variables: {
        input: {
          diffs: [updateEstimationPayload],
        },
      },
      refetchQueries: [GetHistoryUpdatesForObjectIdDocument],
      onCompleted: (data) => {
        const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(data.updateModularObject?.[0]?.approvals);
        const isSaved = !fieldsPendingApprovalMap[ESTIMATION_FIELD_NAME];

        const SUCCESS_MESSAGE = isSaved
          ? 'Successfully saved estimation'
          : 'Successfully requested approval for estimation';

        addToastSuccess(SUCCESS_MESSAGE);

        metrics.track('[Object Card] Updated Estimation', {
          estimation: newFieldValue,
        });
      },
      onError: () => addToastError('Failed to update estimation, please try again'),
    });
  }, [objectCardData?.id, updateEstimationMutation]);

  const handleChange = useCallback(
    debounce(async (e: ChangeEvent<HTMLInputElement>) => {
      const newFieldValue = e.target.value;
      if (newFieldValue === resourceCost) return;
      await updateEstimation(newFieldValue);
    }, 2000),
    [objectCardData?.id, updateEstimationMutation],
  );

  const handleBlur = useCallback(async (e: FocusEvent<HTMLInputElement>) => {
    const newFieldValue = e.target.value;
    if (newFieldValue === resourceCost) return;
    handleChange.cancel();
    await updateEstimation(newFieldValue);
  }, [handleChange, resourceCost, updateEstimation]);

  const icon = resourceCostUnit === 'hours' ? faHourglassHalf : faTriangle;

  return (
    <div className='flex relative items-center h-[28px] gap-[8px]'>
      <div className='w-full grow'>
        {canUserEdit && (
          <FontAwesomeIcon
            icon={icon}
            className={cx('absolute translate-x-2.5 translate-y-3', {
              'text-neutral-400': !resourceCost,
            })}
          />
        )}
        <NumberInput
          fieldToWatch='resourceCost'
          inputProps={{
            className: 'pl-9 w-[180px] !grow-0',
            placeholder: `Enter ${resourceCostUnit}`,
            name: 'resourceCost',
            required: true,
            autoComplete: 'off',
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          disablePrismaticField
        />
      </div>
    </div>
  );
}
