import UploadCsvUserFlow from '@/components/flows/UploadCsvUserFlow/UploadCsvUserFlow';
import { useDriverListContext } from './DriverList.context';
import DriverPlaceholder from './DriverPlaceholder';
import DriverSectionContent from './DriverSectionContent';
import useMap from './hooks/useMap';

interface DriverSectionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hardware: any;
  hardwareType: string;
}

export default function DriverSection ({
  hardware,
  hardwareType,
}: Readonly<DriverSectionProps>): JSX.Element {
  const { drivers } = useDriverListContext();

  const shouldShowPlaceholder = !drivers?.length;
  const map = useMap([
    ['openCsvUpload', false],
  ]);

  const openCSVUploader = (open = true) => map.set('openCsvUpload', open);

  return (
    <div className='flex relative flex-col'>
      {!shouldShowPlaceholder ?
        <DriverSectionContent openCSVUploader={openCSVUploader} />
        : (
          <DriverPlaceholder
            openCSVUploader={openCSVUploader}
          />
        )}
      <UploadCsvUserFlow
        open={Boolean(map.get('openCsvUpload'))}
        setOpen={openCSVUploader}
        hardware={hardware}
        hardwareType={hardwareType}
      />
    </div>
  );
}
