import type { User } from '@/__generated__/types';
import { useGetUsersByOrganizationQuery } from '@/components/Company/UserTable/getUsersByOrganization.generated';
import { Loader } from '@/components/loader';
import UserSearchBar from '@/components/modals/CollaboratorDiscovery/UserSearchBar';
import Color from '@/components/types/Color.enum';
import { Button } from '@/designSystemComponents/Button';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useMemo, useState } from 'react';
import { useGetUsersByEmailLazyQuery } from '../AddCollaborators/GetUsersByEmail.query.generated';

type UserAndIsExternal = User & { isExternal: boolean };

interface UpdateAssigneeProps {
  handleUpdateAssignee: (assigneeId: string) => void;
}

export default function UpdateAssignee ({ handleUpdateAssignee }: UpdateAssigneeProps) {
  const [user, setUser] = useState<User | null>(null);
  const [isUpdating, setIsUpdating] = useState(false); // This is a naive loading state, it starts after the user submits "Update"
  const users = useMemo(() => user ? [user] : [], [user]);
  const [externalUserOptions, setExternalUserOptions] = useState<UserAndIsExternal[]>([]);

  const placeholder = useMemo(() => user ? '' : 'Find user by name or email', [user]);

  const loggedInUser = useLoggedInUser();
  const { data: orgUsersData } = useGetUsersByOrganizationQuery({
    variables: {
      orgID: loggedInUser?.organizationId,
    },
  });
  const orgUsers = useMemo(() => orgUsersData?.getUsersByOrganization ?? [], [orgUsersData]);

  const [getUserByEmail] = useGetUsersByEmailLazyQuery({
    onCompleted: (data) => {
      const [externalUser] = data.getUsersByEmail;

      setExternalUserOptions([
        ...externalUserOptions,
        {
          ...externalUser as User,
          isExternal: true,
        },
      ]);
    },
  });

  const userOptions = useMemo(() => [
    ...orgUsers,
    ...users,
  ], [orgUsers, users]);

  const clearUser = () => {
    setUser(null);
  };

  const handleSubmit = () => {
    setIsUpdating(true);
    handleUpdateAssignee(user?.id ?? '');
  };

  return (
    <div className='flex flex-col gap-[24px] p-[8px] w-full'>
      <div className='flex flex-col gap-[4px]'>
        <div className='effra-20'>Update Assignee</div>
      </div>
      <div className='flex flex-col gap-[4px]'>
        <label htmlFor='assignee' className='effra-10 text-gray-40'>Assignee</label>
        <UserSearchBar
          placeholder={placeholder}
          users={userOptions}
          setUsers={setUser}
          addedUsers={users}
          removeUser={clearUser}
          addUser={setUser}
          initialUsers={[]}
          getUserByEmail={getUserByEmail}
          singleUser
        />
      </div>
      <div className='flex grow gap-[24px]'>
        <Button
          onClick={handleSubmit}
          figmaProps={{ size: 'large', style: 'fill' }}
          disabled={!user || isUpdating}
          innerClassName='flex items-center gap-2'
        >
          {isUpdating && <Loader color={Color.WHITE} className='w-[24px]' />}
          {isUpdating ? 'Updating' : 'Update Assignee'}
        </Button>
      </div>
    </div>
  );
}
