import { useLayoutEffect, useMemo, useState } from 'react';

// Libs
import metrics from '@/util/metrics';
import cx from 'classnames';

// Sidenav Components
import EnvironmentIndicator from './components/EnvironmentIndicator';
import NewObjectButton from './components/NewObjectButton';
import SidenavBody from './components/SidenavBody';
import SidenavCollapseButton from './components/SidenavCollapseButton';
import SidenavFooter from './components/SidenavFooter';
import SidenavHeader from './components/SidenavHeader';
import SubscriptionInfo from './components/SubscriptionInfo';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import InviteExternalButton from './components/InviteExternalButton';
import SidenavProvider from './Sidenav.context';

export function SideNavigation (): JSX.Element {
  const router = useRouter();
  const { sidenavInviteButton: showSidenavInviteButton } = useFlags();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const isOnSettingsPage = useMemo(
    () => (router.pathname.includes('/settings') || router.pathname === '/import/smartsheet'),
    [router.pathname],
  );

  const toggleSidenav = () => {
    metrics.track('sidenav - toggled', {
      toggledTo: isExpanded ? 'collapsed' : 'expanded',
    });
    setIsExpanded(!isExpanded);
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      // Auto-collapse if window is less that 750px
      if (window.innerWidth < 750 && isExpanded) {
        setIsExpanded(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isExpanded]);

  return (
    <SidenavProvider isExpanded={isExpanded}>
      <div
        data-testid='sidenav'
        className={cx(
          'bg-[#5a0ca2] flex flex-col @container/sidenav transition-all transform-gpu group relative shrink-0',
          {
            'w-[186px]': isExpanded,
            'w-16': !isExpanded,
          },
        )}
      >
        <EnvironmentIndicator isExpanded={isExpanded} />

        <div
          className={cx('flex flex-col gap-[16px] grow overflow-hidden', {
            'px-[8px] py-[16px]': isExpanded,
            'p-[8px]': !isExpanded,
          })}
        >
          {/* Header */}
          <div className='shrink-0 flex flex-col items-center @expanded/sidenav:items-stretch gap-[16px] divide-y-1 divide-y-white/50 @expanded/sidenav:divide-y-0 @expanded/sidenav:gap-[18px] @expanded/sidenav:px-[8px]'>
            <SidenavHeader expandSidenav={() => setIsExpanded(true)} isExpanded={isExpanded} />
            {!isOnSettingsPage &&
              (
                <div className='flex flex-col items-center gap-[8px] @expanded/sidenav:items-stretch'>
                  {showSidenavInviteButton && <InviteExternalButton />}
                  <NewObjectButton expandSidenav={() => setIsExpanded(true)} />
                </div>
              )}
          </div>

          {/* Body */}
          <div className='flex overflow-hidden relative grow'>
            <SidenavBody isOnSettingsPage={isOnSettingsPage} />
          </div>

          {/* Footer */}
          <div className='shrink-0 @expanded/sidenav:px-[8px] flex flex-col items-center @expanded/sidenav:items-stretch gap-[16px]'>
            <SubscriptionInfo />
            <SidenavFooter />
            <SidenavCollapseButton onClick={toggleSidenav} isExpanded={isExpanded} />
          </div>
        </div>
      </div>
    </SidenavProvider>
  );
}
