import type { TypedTypePolicies } from '@/__generated__/apollo-type-policies';
import { InMemoryCache } from '@apollo/client';

const typePolicies: TypedTypePolicies = {
  ModularObject: {
    fields: {
      //     isEditor: {
      //       read: (_, context) => {
      //         // TODO: Keep this code as a reference for when we need to add local state fields
      //         // We can't use this right now because shares does no exist on the modular object just yet
      //         //  it is however a good reference for local state management in apollo until we have a second example
      //         // const loggedInUser = context.cache.readQuery<User>({ query: GetLoggedInUserDocument });
      //         // // @ts-expect-error
      //         // const sharesRef = readField<ModularObject, 'shares'>(context, 'shares');

      //         // const isEditor = sharesRef.some((shareRef) => {
      //         //   const role = readField<Share, 'role'>(context, 'role', shareRef);
      //         //   const shareUserId = readField<Share, 'userId'>(context, 'userId', shareRef);

      //         //   return shareUserId === loggedInUser?.id && role.toUpperCase() === 'EDITOR';
      //         // });

      //         // return isEditor;
      //       },
      //     },
    },
  },
  Query: {
    fields: {
      getRecentActivityByUserId: {
        keyArgs: ['input', ['userId', 'type']],
        merge: mergeNewPage,
      },
      teamMembers: {
        keyArgs: ['input', ['query', ['teamId']]],
        merge: mergeNewPage,
      },
    },
  },
};

export const getNewApolloCache = () => new InMemoryCache({ typePolicies });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mergeNewPage (existing: any, incoming: any) {
  // TODO: Handle case where an entire new non-overlapping page is loaded to the front of the list
  const edges = [];
  let pageInfo = { ...incoming.pageInfo };

  // Compare to make sure we don't duplicate the edges
  if (existing?.edges && incoming?.edges) {
    const incomingIds = incoming.edges.map((edge) => edge.__ref);
    const existingIds = existing.edges.map((edge) => edge.__ref);

    const newExistingEdges = existing.edges.filter((edge) => !incomingIds.includes(edge.__ref));

    if (existingIds.indexOf(incomingIds[incomingIds.length - 1]) !== -1) {
      // Insert the new edges before the existing edges
      edges.push(...incoming.edges, ...newExistingEdges);
      // Dont update page info, just merge the edges
      pageInfo = { ...existing.pageInfo };
    } else {
      // Append the new edges to the existing edges
      edges.push(...newExistingEdges, ...incoming.edges);
    }
  } else {
    edges.push(...incoming.edges);
  }

  return {
    pageInfo,
    edges,
  };
}
