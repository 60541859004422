import ToggleChevronButton from '@/components/Button/ToggleChevronButton';
import DriverPill from '@/components/Pill/DriverPill/DriverPill';
import SubDriverPill from '@/components/Pill/SubDriverPill/SubDriverPill';
import { DriverPillColor } from '@/models/driver.model';
import { useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { selectRootState } from '@/state/selectors';
import getIcon from '@/util/getIcon';
import { getIsOwner, getSameOrg } from '@/util/lookup.functions';
import cx from 'classnames';
import { useMemo, useState } from 'react';
import { dehydrateModularObject } from '../../../ModularObject/utils';
import { type DriversPendingApproval, useDriverListContext } from '../DriverList.context';
import { useGetObjectCardSubDriversLazyQuery } from '../getObjectCardSubDrivers.generated';
import { useListActionContext } from '../ListActions.context';
import LoadingSpinner from '../LoadingSpinner';
import DriverListRowLine, { ParentColor } from './DriverListRowLine';

const PAGE_SIZE = 0; // Indicates we do not want to paginate the subdrivers, just load all of them
const SELECTED_PAGE = 1;

export interface DriverListRowProps {
  driver: DriversPendingApproval;
  isSubDriver?: boolean;
}

export default function DriverListRow ({ driver, isSubDriver }: Readonly<DriverListRowProps>): JSX.Element {
  const { hasSubDriversInDriverList } = useDriverListContext();
  const { selectObject, unselectObject, selectedObjects } = useListActionContext();
  const state = useAppSelector(selectRootState);
  const {
    session: { user },
    users: userState,
  } = state;
  const { openViewObjectCardDrawer } = useObjectCardContext();
  const [areSubDriversExpanded, setAreSubDriversExpanded] = useState<boolean>(false);
  const { isPending } = driver;

  const [getSubDrivers, { data: getSubDriversData, loading: isLoadingSubDrivers }] =
    useGetObjectCardSubDriversLazyQuery();

  const subDrivers = useMemo(
    () => getSubDriversData?.getModularObjectByIDs?.[0]?.drivers?.edges.map(({ node }) => node) ?? [],
    [getSubDriversData],
  );

  const driverStatus = driver?.status ?? 'TO_DO';

  const openCard = (_driver): void => {
    openViewObjectCardDrawer({ modularObjectId: _driver.id });
  };

  const currentParent = {
    ...driver?.parent,
    ...dehydrateModularObject(driver?.parent?.data, true),
  };

  const openParent = (_currentParent): void => {
    openViewObjectCardDrawer({ modularObjectId: _currentParent?.id });
  };

  const handleToggleSubDrivers = async (): Promise<void> => {
    // Check if we're closing the subtasks, and if so reset the page number and close the subtasks
    if (areSubDriversExpanded) {
      setAreSubDriversExpanded(false);
      return;
    }

    await getSubDrivers({
      variables: {
        modularObjectId: driver?.id,
        nameContains: null,
        pageInfoInput: { pageSize: PAGE_SIZE, selectedPage: SELECTED_PAGE },
      },
    });

    setAreSubDriversExpanded(true);
  };

  const handleMilestoneListCheckboxChange = (event, driver): void => {
    if (event.target.checked) {
      selectObject(driver, event);
    } else {
      unselectObject(driver, 'drivers');
    }
  };

  const isChecked = Boolean(selectedObjects?.drivers?.[driver?.id]);

  const getParentColor = (parent) => {
    let parentColor = null;
    if (!getIsOwner(parent, user) && !getSameOrg(parent, user, userState)) {
      parentColor = ParentColor.Teal;
    }
    if (!getSameOrg(parent, user, userState) || getIsOwner(parent, user)) {
      parentColor = ParentColor.Purple;
    }

    return parentColor;
  };

  // We only want to show the UI indicators for subtasks (chevron) if there are subdrivers on the initial query
  const hasSubDrivers = useMemo(() => Boolean(driver?.drivers?.edges?.length || subDrivers?.length), [
    driver?.drivers?.edges,
    subDrivers,
  ]);

  return (
    <div
      className={cx('flex flex-col justify-center flex-1', {
        'pl-[23px]': hasSubDriversInDriverList && !hasSubDrivers,
        'opacity-60': isPending,
      })}
    >
      <div className='flex flex-1'>
        {hasSubDrivers && (
          <ToggleChevronButton
            className='pl-[8px]'
            expanded={areSubDriversExpanded}
            onClick={handleToggleSubDrivers}
          />
        )}
        <DriverListRowLine
          assigneeId={driver?.assigneeId}
          ownerId={driver?.ownerId}
          onChange={handleMilestoneListCheckboxChange}
          onClick={_ => {
            openCard(driver);
          }}
          onKeyDown={_ => {
            openCard(driver);
          }}
          onParentClick={_ => {
            openParent(currentParent);
          }}
          id={driver?.id}
          isChecked={isChecked}
          name={driver?.name}
          parentColor={getParentColor(currentParent)}
          parentIcon={getIcon(currentParent?.name?.toLowerCase())}
          parentName={currentParent?.name}
          pill={!isSubDriver ?
            (
              <DriverPill
                type={driver?.template?.name}
                data={{
                  assignee: driver?.assigneeId,
                  isSameOrg: getSameOrg(driver, user, userState),
                }}
              />
            ) :
            (
              <SubDriverPill
                color={DriverPillColor.Black}
                type={driver?.template?.subType}
              />
            )}
          startDate={driver?.startDate}
          status={driverStatus}
          targetDate={driver?.targetDate}
          driver={driver}
        />
      </div>
      {hasSubDrivers && Boolean(subDrivers?.length) && (
        <>
          <div
            className={cx('flex flex-col transition-all overflow-hidden w-full', {
              'h-0': !areSubDriversExpanded,
              'h-auto': areSubDriversExpanded,
            })}
          >
            {isLoadingSubDrivers ?
              (
                <div className='flex flex-1 justify-center items-center'>
                  <LoadingSpinner isLoading={true} className='w-[32px] h-[32px]' />
                </div>
              ) :
              subDrivers.map((subDriver) => (
                <DriverListRow
                  key={subDriver?.id}
                  driver={subDriver as DriversPendingApproval}
                  isSubDriver={true}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
}
