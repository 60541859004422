import type { ExcelMapping, JiraMapping, Scalars } from '@/__generated__/types';
import {
  type CreatePrismaticExcelMappingMutation,
  useCreatePrismaticExcelMappingMutation,
} from '@/graphql/prismatic/createPrismaticExcelMapping.generated';
import {
  type CreatePrismaticJiraMappingMutation,
  useCreatePrismaticJiraMappingMutation,
} from '@/graphql/prismatic/createPrismaticJiraMapping.generated';
import { useDeletePrismaticJiraMappingMutation } from '@/graphql/prismatic/deletePrismaticJiraMapping.generated';
import {
  type GetPrismaticExcelMappingQuery,
  useGetPrismaticExcelMappingQuery,
} from '@/graphql/prismatic/getPrismaticExcelMapping.generated';
import {
  type GetPrismaticJiraMappingQuery,
  useGetPrismaticJiraMappingQuery,
} from '@/graphql/prismatic/getPrismaticJiraMapping.generated';
import { useUpdatePrismaticExcelMappingMutation } from '@/graphql/prismatic/updatePrismaticExcelMapping.generated';
import { TemplateType } from '@/models/template.model';
import { useAppSelector } from '@/state/hooks';
import type { ApolloQueryResult, FetchResult } from '@apollo/client';
import { createContext, type PropsWithChildren, useCallback, useContext, useMemo } from 'react';

interface PrismaticContextType {
  isOwner?: boolean;
  objectId?: string;
  templateType?: string;
  canCreateJiraTask: boolean;
  canDeleteJiraTask: boolean;
  createExcelMapping: () => Promise<FetchResult<CreatePrismaticExcelMappingMutation>>;
  createJiraMapping: () => Promise<FetchResult<CreatePrismaticJiraMappingMutation>>;
  createJiraTicket: () => Promise<string>;
  deleteExcelMapping: (moduleId: string) => Promise<void>;
  deleteJiraWebhook: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  excelIntegrationSetup: Record<string, any>;
  excelMappingData: ExcelMapping;
  getExcelFilesEndpoint: string;
  getExcelSheetsEndpoint: string;
  isJiraTaskCreated: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jiraIntegrationSetup: Record<string, any>;
  jiraMappingData: JiraMapping;
  refetchExcelMappingData: () => Promise<ApolloQueryResult<GetPrismaticExcelMappingQuery>>;
  refetchJiraMappingData: () => Promise<ApolloQueryResult<GetPrismaticJiraMappingQuery>>;
  refreshExcelSubscription: (selectedFileId: string) => Promise<void>;
  subscribeToExcelFileEndpoint: string;
  submitExcelMapping: (
    selectedFileId: string,
    selectedSheetName: string,
    mapping: Scalars['MapOfExcelCellMappings']['output'],
  ) => Promise<void>;
  updateJiraTicket: () => Promise<void>;
  listSmartsheetSheets: () => Promise<Response>;
  listSmartsheetColumns: (sheetId: number) => Promise<Response>;
  getSmartsheetSheets: (sheetIds: string[]) => Promise<Response>;
  isSmartsheetConnected: boolean;
}

interface PrismaticProviderProps extends PropsWithChildren {
  isOwner?: boolean;
  objectId?: string;
  templateType?: string;
}

const PrismaticContext = createContext<PrismaticContextType>(null);

export function usePrismaticContext (): PrismaticContextType {
  const context = useContext(PrismaticContext);

  if (context === undefined) {
    throw new Error('usePrismaticContext must be used within a PrismaticProvider');
  }

  return context;
}

export default function PrismaticProvider ({
  children,
  isOwner,
  objectId,
  templateType,
}: Readonly<PrismaticProviderProps>): JSX.Element {
  const prismaticUser = useAppSelector((state) => {
    return state.session.prismaticUser;
  });

  // Integrations setup
  const jiraIntegrationSetup = useMemo(() => {
    if (!prismaticUser) return;
    return prismaticUser
      .instances
      .nodes
      .find((node) =>
        node.integration.name === 'Jira Task Sync' &&
        node.configState === 'FULLY_CONFIGURED'
      );
  }, [prismaticUser]);

  const excelIntegrationSetup = useMemo(() => {
    if (!prismaticUser) return;
    return prismaticUser
      .instances
      .nodes
      .find((node) =>
        node.integration.name === 'Excel Link' &&
        node.configState === 'FULLY_CONFIGURED'
      );
  }, [prismaticUser]);

  const smartsheetIntegrationSetup = useMemo(() => {
    if (!prismaticUser) return;
    return prismaticUser
      .instances
      .nodes
      .find((node) =>
        node.integration.name === 'Smartsheet Import' &&
        node.configState === 'FULLY_CONFIGURED'
      );
  }, [prismaticUser]);

  // Flows
  const jiraIntegrationFlow = useMemo(() => {
    if (!jiraIntegrationSetup) return;
    return jiraIntegrationSetup
      ?.userLevelConfigVariables
      ?.nodes?.[0]
      ?.config
      ?.flowConfigs
      ?.nodes;
  }, [jiraIntegrationSetup]);

  const excelIntegrationFlow = useMemo(() => {
    if (!excelIntegrationSetup) return;
    return excelIntegrationSetup
      ?.userLevelConfigVariables
      ?.nodes?.[0]
      ?.config
      ?.flowConfigs
      ?.nodes;
  }, [excelIntegrationSetup]);

  const smartsheetIntegrationFlow = useMemo(() => {
    if (!smartsheetIntegrationSetup) return;
    return smartsheetIntegrationSetup
      ?.userLevelConfigVariables
      ?.nodes?.[0]
      ?.config
      ?.flowConfigs
      ?.nodes;
  }, [smartsheetIntegrationSetup]);

  // Get Mappings
  const { data: excelMappingData, refetch: refetchExcelMappingData } = useGetPrismaticExcelMappingQuery({
    variables: {
      id: objectId,
    },
    skip: !objectId,
  });

  const { data: jiraMappingData, refetch: refetchJiraMappingData } = useGetPrismaticJiraMappingQuery({
    variables: { id: objectId },
    skip: !objectId,
  });

  const getPrismaticExcelMapping = excelMappingData?.getPrismaticExcelMapping;

  // Mutations
  const [createExcelMapping] = useCreatePrismaticExcelMappingMutation();
  const [updateExcelMapping] = useUpdatePrismaticExcelMappingMutation();
  const [createJiraMapping] = useCreatePrismaticJiraMappingMutation();
  const [deleteJiraMapping] = useDeletePrismaticJiraMappingMutation();

  // Memoized endpoints
  const getExcelFilesEndpoint = useMemo(() => {
    if (!excelIntegrationFlow) return;
    return excelIntegrationFlow
      .find((node) => node.instanceFlowConfig.flow.name === 'Get Files from Sharepoint')
      ?.webhookUrl;
  }, [excelIntegrationFlow]);

  const getExcelSheetsEndpoint = useMemo(() => {
    if (!excelIntegrationFlow) return;
    return excelIntegrationFlow
      .find((node) => node.instanceFlowConfig.flow.name === 'Download File from Sharepoint')
      ?.webhookUrl;
  }, [excelIntegrationFlow]);

  const subscribeToExcelFileEndpoint = useMemo(() => {
    if (!excelIntegrationFlow) return;
    return excelIntegrationFlow
      .find((node) => node.instanceFlowConfig.flow.name === 'Subscribe to Sharepoint File Events')
      ?.webhookUrl;
  }, [excelIntegrationFlow]);

  const createJiraTicketEndpoint = useMemo(() => {
    if (!jiraIntegrationFlow) return;
    return jiraIntegrationFlow
      .find((node) => node.instanceFlowConfig.flow.name === 'Create Task in Jira')
      ?.webhookUrl;
  }, [jiraIntegrationFlow]);

  const deleteJiraTicketEndpoint = useMemo(() => {
    if (!jiraIntegrationFlow) return;
    return jiraIntegrationFlow
      .find((node) => node.instanceFlowConfig.flow.name === 'Delete Jira Webhook')
      ?.webhookUrl;
  }, [jiraIntegrationFlow]);

  const updateJiraTicketEndpoint = useMemo(() => {
    if (!jiraIntegrationFlow) return;
    return jiraIntegrationFlow
      .find((node) => node.instanceFlowConfig.flow.name === 'Task Updated')
      ?.webhookUrl;
  }, [jiraIntegrationFlow]);

  const listSmartsheetSheetsEndpoint = useMemo(() => {
    if (!smartsheetIntegrationFlow) return;
    return smartsheetIntegrationFlow
      .find((node) => node.instanceFlowConfig.flow.name === 'List Sheets')
      ?.webhookUrl;
  }, [smartsheetIntegrationFlow]);

  const listSmartsheetColumnsEndpoint = useMemo(() => {
    if (!smartsheetIntegrationFlow) return;
    return smartsheetIntegrationFlow
      .find((node) => node.instanceFlowConfig.flow.name === 'List Columns')
      ?.webhookUrl;
  }, [smartsheetIntegrationFlow]);

  const getSmartsheetSheetsEndpoint = useMemo(() => {
    if (!smartsheetIntegrationFlow) return;
    return smartsheetIntegrationFlow
      .find((node) => node.instanceFlowConfig.flow.name === 'Get Sheets')
      ?.webhookUrl;
  }, [smartsheetIntegrationFlow]);

  // Submit handlers
  const submitExcelMapping = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (selectedFileId: string, selectedSheetName: string, mapping: any): Promise<void> => {
      await fetch(subscribeToExcelFileEndpoint, { headers: { 'file-id': selectedFileId } });
      if (getPrismaticExcelMapping) {
        await updateExcelMapping({
          variables: {
            input: {
              ...getPrismaticExcelMapping,
              cellMapping: mapping,
            },
          },
        });
      } else {
        await createExcelMapping({
          variables: {
            input: {
              modularObjectId: objectId,
              sharepointFileId: selectedFileId,
              sheetName: selectedSheetName,
              cellMapping: mapping,
            },
          },
        });
      }
    },
    [createExcelMapping, getPrismaticExcelMapping, objectId, subscribeToExcelFileEndpoint, updateExcelMapping],
  );

  const refreshExcelSubscription = useCallback(async (selectedFileId: string) => {
    await fetch(subscribeToExcelFileEndpoint, { headers: { 'file-id': selectedFileId } });
  }, [subscribeToExcelFileEndpoint]);

  const deleteExcelMapping = useCallback(async (moduleId: string): Promise<void> => {
    delete getPrismaticExcelMapping.cellMapping[moduleId];
    await updateExcelMapping({
      variables: {
        input: {
          ...getPrismaticExcelMapping,
          cellMapping: getPrismaticExcelMapping.cellMapping,
        },
      },
    });
  }, [getPrismaticExcelMapping, updateExcelMapping]);

  const deleteJiraWebhook = useCallback(async (): Promise<void> => {
    await deleteJiraMapping({
      variables: {
        id: jiraMappingData?.getPrismaticJiraMapping?.id,
      },
    });

    await refetchJiraMappingData();
  }, [
    deleteJiraMapping,
    deleteJiraTicketEndpoint,
    jiraMappingData?.getPrismaticJiraMapping?.id,
    jiraMappingData?.getPrismaticJiraMapping?.jiraWebhookId,
    refetchJiraMappingData,
  ]);

  const createJiraTicket = useCallback(async (): Promise<string> => {
    if (createJiraTicketEndpoint) {
      const prismaticData = await fetch(createJiraTicketEndpoint, { headers: { 'object-id': objectId } });

      const prismaticJson = await prismaticData.json();

      await createJiraMapping({
        variables: {
          input: {
            jiraIssueId: prismaticJson.id,
            modularObjectId: objectId,
            jiraIssueKey: prismaticJson.key,
            fieldMapping: {},
            jiraWebhookId: `${prismaticJson.webhookId}`,
            jiraTicketUrl: prismaticJson.url,
          },
        },
      });

      await refetchJiraMappingData();

      return prismaticJson.url;
    }
  }, [createJiraTicketEndpoint, createJiraMapping, objectId, refetchJiraMappingData]);

  const updateJiraTicket = useCallback(async (): Promise<void> => {
    if (updateJiraTicketEndpoint) {
      await fetch(updateJiraTicketEndpoint, { headers: { 'object-id': objectId } });

      await refetchJiraMappingData();
    }
  }, [updateJiraTicketEndpoint, objectId, refetchJiraMappingData]);

  const listSmartsheetSheets = useCallback(async (): Promise<Response> => {
    if (listSmartsheetSheetsEndpoint) {
      return await fetch(listSmartsheetSheetsEndpoint);
    }
  }, [listSmartsheetSheetsEndpoint]);

  const listSmartsheetColumns = useCallback(async (sheetId: number): Promise<Response> => {
    if (listSmartsheetColumnsEndpoint) {
      return await fetch(listSmartsheetColumnsEndpoint, { headers: { 'sheet-id': sheetId.toString() } });
    }
  }, [listSmartsheetColumnsEndpoint]);

  const getSmartsheetSheets = useCallback(async (sheetIds: string[]): Promise<Response> => {
    if (getSmartsheetSheetsEndpoint) {
      const body = {
        sheetIds,
      };

      return await fetch(getSmartsheetSheetsEndpoint, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
    }
  }, [getSmartsheetSheetsEndpoint]);

  // Calculated values
  const isJiraEnabledForObject = jiraIntegrationFlow && isOwner && templateType === TemplateType.Driver;
  const isJiraTaskCreated = Boolean(isJiraEnabledForObject && jiraMappingData?.getPrismaticJiraMapping?.jiraIssueId);
  const canCreateJiraTask = isJiraEnabledForObject && !isJiraTaskCreated;
  const canDeleteJiraTask = isJiraTaskCreated && isJiraEnabledForObject;
  const isSmartsheetConnected = Boolean(smartsheetIntegrationSetup);

  const value = useMemo(
    () => ({
      isOwner,
      objectId,
      templateType,
      canCreateJiraTask,
      canDeleteJiraTask,
      createExcelMapping,
      createJiraMapping,
      createJiraTicket,
      deleteExcelMapping,
      deleteJiraWebhook,
      excelIntegrationSetup,
      excelMappingData: getPrismaticExcelMapping as ExcelMapping,
      getExcelFilesEndpoint,
      getExcelSheetsEndpoint,
      isJiraTaskCreated,
      jiraIntegrationSetup,
      jiraMappingData: jiraMappingData?.getPrismaticJiraMapping as JiraMapping,
      refetchExcelMappingData,
      refetchJiraMappingData,
      refreshExcelSubscription,
      subscribeToExcelFileEndpoint,
      submitExcelMapping,
      updateJiraTicket,
      listSmartsheetSheets,
      listSmartsheetColumns,
      getSmartsheetSheets,
      isSmartsheetConnected,
    }),
    [
      canCreateJiraTask,
      canDeleteJiraTask,
      createExcelMapping,
      createJiraMapping,
      createJiraTicket,
      deleteExcelMapping,
      deleteJiraWebhook,
      excelIntegrationSetup,
      excelMappingData,
      getExcelFilesEndpoint,
      getExcelSheetsEndpoint,
      isJiraTaskCreated,
      isOwner,
      jiraIntegrationSetup,
      jiraMappingData,
      objectId,
      refetchExcelMappingData,
      refetchJiraMappingData,
      refreshExcelSubscription,
      submitExcelMapping,
      subscribeToExcelFileEndpoint,
      templateType,
      updateJiraTicket,
      listSmartsheetSheets,
      listSmartsheetColumns,
      getSmartsheetSheets,
      isSmartsheetConnected,
    ],
  );

  return (
    <PrismaticContext.Provider value={value}>
      {children}
    </PrismaticContext.Provider>
  );
}
