import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type UpdateModularObjectMutationVariables = Types.Exact<{
  input: Types.UpdateModularObjectInput;
}>;


export type UpdateModularObjectMutation = { __typename?: 'Mutation', updateModularObject: Array<{ __typename?: 'ModularObject', id: string, name: string | null, ownerId: string | null, assigneeId: string | null, visibility: string | null, startDate: any | null, targetDate: any | null, approvalsEnabled: boolean | null, duration: { __typename?: 'ModularObjectDuration', startDate: any | null, targetDate: any | null } | null, externalLink: { __typename?: 'ObjectToActivateLink', url: string | null } | null, owner: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, profileImage: string | null, organizationId: string | null } | null, assignee: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, profileImage: string | null } | null }> };


export const UpdateModularObjectDocument = gql`
    mutation updateModularObject($input: UpdateModularObjectInput!) {
  updateModularObject(input: $input) {
    id
    name
    ownerId
    assigneeId
    visibility
    startDate
    targetDate
    duration {
      startDate
      targetDate
    }
    externalLink {
      url
    }
    approvalsEnabled
    owner(includeDeleted: true) {
      id
      email
      firstName
      lastName
      profileImage
      organizationId
    }
    assignee(includeDeleted: true) {
      id
      email
      firstName
      lastName
      profileImage
    }
  }
}
    `;
export type UpdateModularObjectMutationFn = Apollo.MutationFunction<UpdateModularObjectMutation, UpdateModularObjectMutationVariables>;

/**
 * __useUpdateModularObjectMutation__
 *
 * To run a mutation, you first call `useUpdateModularObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModularObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModularObjectMutation, { data, loading, error }] = useUpdateModularObjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModularObjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModularObjectMutation, UpdateModularObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModularObjectMutation, UpdateModularObjectMutationVariables>(UpdateModularObjectDocument, options);
      }
export type UpdateModularObjectMutationHookResult = ReturnType<typeof useUpdateModularObjectMutation>;
export type UpdateModularObjectMutationResult = Apollo.MutationResult<UpdateModularObjectMutation>;
export type UpdateModularObjectMutationOptions = Apollo.BaseMutationOptions<UpdateModularObjectMutation, UpdateModularObjectMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateModularObjectMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateModularObject })
 *   )
 * })
 */
export const mockUpdateModularObjectMutation = (resolver: Parameters<typeof graphql.mutation<UpdateModularObjectMutation, UpdateModularObjectMutationVariables>>[1]) =>
  graphql.mutation<UpdateModularObjectMutation, UpdateModularObjectMutationVariables>(
    'updateModularObject',
    resolver
  )
