import { MILESTONE_TEMPLATE_ID } from '@/components/common/Drivers/DriverSection/constants';
import RapidDriverCreation from '@/components/common/Drivers/DriverSection/RapidDriverCreation';
import { OffSetPagination } from '@/components/OffSetPagination/OffSetPagination';
import { PageSizeSelectionDropdown } from '@/components/OffSetPagination/PageSizeSelectionDropdown';
import { useOffSetPagination } from '@/components/OffSetPagination/useOffSetPagination';
import { useAppDispatch } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { setModularObjectModal } from '@/state/slices/session.slice';
import { createAndDownloadFile } from '@/util/downloadFile';
import { getModuleByName } from '@/util/lookup.functions';
import cx from 'classnames';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { getTheBandBackTogether } from '../../ModularObject/Card/ObjectCard.util';
import DriverColumnHeader from './DriverColumnHeader';
import DriverList from './DriverList';
import {
  useGetObjectCardSubDriversLazyQuery,
  useGetObjectCardSubDriversQuery,
} from './getObjectCardSubDrivers.generated';
import HeaderName from './HeaderName';
import { useListActionContext } from './ListActions.context';
import LoadingSpinner from './LoadingSpinner';
import { flattenDrivers } from './utils';

interface DriverSectionContentProps {
  openCSVUploader: () => void;
}

export default function DriverSectionContent ({ openCSVUploader }: DriverSectionContentProps): JSX.Element {
  const {
    objectCardData,
    activeModularObjectId,
  } = useObjectCardContext();

  const hasSubDrivers = objectCardData.drivers?.edges.length > 0;
  const { selectedObjects, setSelectedObjects, setDefaultSelectedObjects } = useListActionContext();
  const dispatch = useAppDispatch();
  const [filterTerm, setFilterTerm] = useState<string>(null);
  const [parentFilter, setParentFilter] = useState<string>(null);
  const [startDateFilter, setStartDateFilter] = useState<string>(null);
  const [targetDateFilter, setTargetDateFilter] = useState<string>(null);
  const { onPageChange, selectedPage, onPageSizeChange, selectedPageSize } = useOffSetPagination({});
  const [getSubDrivers] = useGetObjectCardSubDriversLazyQuery();
  const { data: subDriversData, loading: isLoadingSubDrivers } = useGetObjectCardSubDriversQuery({
    variables: {
      modularObjectId: activeModularObjectId,
      nameContains: filterTerm,
      pageInfoInput: {
        pageSize: selectedPageSize,
        selectedPage,
      },
    },
  });

  const handleFilterTermChange = useCallback(
    debounce((filterString: string) => {
      setFilterTerm(filterString);
    }, 1000),
    [],
  );

  const drivers = useMemo(() => {
    return subDriversData?.getModularObjectByIDs?.[0]?.drivers?.edges.map(({ node }) => node) ?? [];
  }, [subDriversData?.getModularObjectByIDs]);
  const pageInfo = subDriversData?.getModularObjectByIDs?.[0]?.drivers?.pageInfo;

  const handleCheckAll = (event): void => {
    const { checked } = event.target;
    if (checked) {
      const allDriversMap = drivers.reduce((acc, driver) => {
        acc[driver.id] = driver;
        return acc;
      }, {});

      setSelectedObjects({
        ...selectedObjects,
        drivers: allDriversMap,
      });
    } else {
      setDefaultSelectedObjects();
    }
  };

  const exportDrivers = async (): Promise<void> => {
    const csvHeaders = 'Name,Type,Parent Name,Start Date,Target Date,Status,Shall Statement\n';
    const { data: getSubDriversData } = await getSubDrivers({
      variables: {
        modularObjectId: activeModularObjectId,
        nameContains: null,
        pageInfoInput: {
          pageSize: subDriversData?.getModularObjectByIDs?.[0]?.drivers?.pageInfo?.totalDrivers,
          selectedPage: 1,
        },
      },
    });

    const allDrivers = getSubDriversData?.getModularObjectByIDs?.[0]?.drivers?.edges.map(({ node }) => node) ?? [];
    const flattenedDrivers = flattenDrivers(allDrivers);

    const csvContent = flattenedDrivers?.map((driver) => {
      const status = driver?.status;
      const shallStatement = getModuleByName(
        getTheBandBackTogether(driver?.template?.modules, driver?.data),
        'shallStatement',
      )?.value;

      let shallStatementParsed = '';

      try {
        if (shallStatement) {
          shallStatementParsed = JSON.parse(shallStatement).content.map((c) =>
            c?.content?.map((cc) => cc?.text ?? '').join(' ')
          ).join('\n');
        }
      } catch (e) {
        shallStatementParsed = shallStatement;
      }

      const row = [
        driver.name,
        driver?.template?.name ?? '',
        driver?.parent?.name ?? '',
        driver.startDate ?
          new Date(driver.startDate).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }) :
          '',
        driver.targetDate ?
          new Date(driver.targetDate).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }) :
          '',
        status ?? '',
        shallStatementParsed ?? '',
      ];

      return row.map(field => (`"${field}"`)).join(',');
    }).join('\n');

    createAndDownloadFile(csvHeaders + csvContent, 'text/csv', 'driver-export.csv');
  };

  return (
    <>
      <DriverColumnHeader
        setFilterTerm={handleFilterTermChange}
        handleExportDrivers={exportDrivers}
        openCSVUploader={openCSVUploader}
      />
      <div
        className={cx('flex items-start gap-3 py-3 border-b border-neutral-200 @container', {
          'pl-[41px] pr-6': hasSubDrivers,
          'px-6': !hasSubDrivers,
        })}
      >
        <HeaderName
          className='flex shrink'
          text={
            <input
              type='checkbox'
              onChange={handleCheckAll}
              className='input-checkbox custom-checkbox checked:bg-primary'
            />
          }
        />
        <HeaderName
          className='flex basis-[60px]'
          text='type'
        />
        <HeaderName
          className='flex justify-center basis-[36px]'
          text='status'
        />
        <HeaderName
          className='flex grow shrink'
          text='name'
        />
        <HeaderName
          className='flex cursor-pointer basis-[72px] link text-primary'
          text='start date'
          sortKey={startDateFilter}
          onClick={() => {
            setParentFilter(null);
            setStartDateFilter(startDateFilter === 'asc' ? 'dsc' : 'asc');
            setTargetDateFilter(null);
          }}
        />
        <HeaderName
          className='flex cursor-pointer basis-[72px] link text-primary'
          text='target date'
          sortKey={targetDateFilter}
          onClick={() => {
            setParentFilter(null);
            setTargetDateFilter(targetDateFilter === 'asc' ? 'dsc' : 'asc');
            setStartDateFilter(null);
          }}
        />
        <HeaderName
          className='flex cursor-pointer basis-[100px]'
          text='parent'
          sortKey={parentFilter}
          onClick={() => {
            setParentFilter(parentFilter === 'asc' ? 'dsc' : 'asc');
            setStartDateFilter(null);
            setTargetDateFilter(null);
          }}
        />
        <HeaderName
          className='w-[5%] basis-[28px] truncate'
          text='assignee'
        />
      </div>
      <div className='flex w-full'>
        <div className='flex flex-col w-full'>
          {isLoadingSubDrivers ? <LoadingSpinner isLoading /> : (
            <div>
              <DriverList
                drivers={drivers}
                filterTerm={filterTerm}
                parentFilter={parentFilter}
                startDateFilter={startDateFilter}
                targetDateFilter={targetDateFilter}
                setOpenDriver={() => (
                  dispatch(setModularObjectModal({
                    isOpen: true,
                  }))
                )}
                setCurrentDriver={(m) => (
                  dispatch(setModularObjectModal({
                    modularObjectId: m.id,
                    templateId: MILESTONE_TEMPLATE_ID,
                  }))
                )}
              />
              <div className='flex relative gap-2 justify-center my-[4px] h-[24px]'>
                <OffSetPagination
                  onPageChange={onPageChange}
                  selectedPage={selectedPage}
                  pageCount={pageInfo?.pageCount}
                />
                <div className='absolute top-0 min-w-[38px] right-[18px]'>
                  <PageSizeSelectionDropdown
                    onPageSizeChange={onPageSizeChange}
                    initialPageSize={selectedPageSize}
                  />
                </div>
              </div>
            </div>
          )}
          <div className='flex py-4'>
            <RapidDriverCreation />
          </div>
        </div>
      </div>
    </>
  );
}
