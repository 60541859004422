import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetObjectCardSubDriversQueryVariables = Types.Exact<{
  modularObjectId: Types.Scalars['UUID']['input'];
  nameContains: Types.InputMaybe<Types.Scalars['String']['input']>;
  pageInfoInput: Types.InputMaybe<Types.DriverPageInfoInput>;
}>;


export type GetObjectCardSubDriversQuery = { __typename?: 'Query', getModularObjectByIDs: Array<{ __typename?: 'ModularObject', id: string, drivers: { __typename?: 'DriverConnections', pageInfo: { __typename?: 'DriverPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, pageCount: number, totalDrivers: number }, edges: Array<{ __typename?: 'DriverEdge', node: { __typename?: 'ModularObject', id: string, assigneeId: string | null, data: any | null, name: string | null, ownerId: string | null, startDate: any | null, status: Types.DriverStatus | null, statusModuleId: string | null, targetDate: any | null, assignee: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, profileImage: string | null, organizationId: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, userId: string | null, role: Types.ShareRole | null }> | null, drivers: { __typename?: 'DriverConnections', edges: Array<{ __typename?: 'DriverEdge', node: { __typename?: 'ModularObject', id: string } }> } | null, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, subType: Types.TemplateSubTypes | null, modules: any | null, name: string | null } | null } }> } | null }> | null };

export type ObjectCardSubDriverFragment = { __typename?: 'ModularObject', id: string, assigneeId: string | null, data: any | null, name: string | null, ownerId: string | null, startDate: any | null, status: Types.DriverStatus | null, statusModuleId: string | null, targetDate: any | null, assignee: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, profileImage: string | null, organizationId: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, userId: string | null, role: Types.ShareRole | null }> | null, drivers: { __typename?: 'DriverConnections', edges: Array<{ __typename?: 'DriverEdge', node: { __typename?: 'ModularObject', id: string } }> } | null, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, subType: Types.TemplateSubTypes | null, modules: any | null, name: string | null } | null };

export const ObjectCardSubDriverFragmentDoc = gql`
    fragment ObjectCardSubDriver on ModularObject {
  id
  assigneeId
  assignee {
    id
    email
    firstName
    lastName
    profileImage
    organizationId
  }
  data
  name
  ownerId
  parent {
    id
    name
  }
  startDate
  status
  statusModuleId
  targetDate
  shares {
    id
    userId
    role
  }
  drivers {
    edges {
      node {
        id
      }
    }
  }
  template {
    id
    type
    subType
    modules
    name
  }
}
    `;
export const GetObjectCardSubDriversDocument = gql`
    query GetObjectCardSubDrivers($modularObjectId: UUID!, $nameContains: String, $pageInfoInput: DriverPageInfoInput) {
  getModularObjectByIDs(ids: [$modularObjectId]) {
    id
    drivers(
      input: {includeDriverRollup: true, nameContains: $nameContains, pageInfo: $pageInfoInput}
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageCount
        totalDrivers
      }
      edges {
        node {
          ...ObjectCardSubDriver
        }
      }
    }
  }
}
    ${ObjectCardSubDriverFragmentDoc}`;

/**
 * __useGetObjectCardSubDriversQuery__
 *
 * To run a query within a React component, call `useGetObjectCardSubDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectCardSubDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectCardSubDriversQuery({
 *   variables: {
 *      modularObjectId: // value for 'modularObjectId'
 *      nameContains: // value for 'nameContains'
 *      pageInfoInput: // value for 'pageInfoInput'
 *   },
 * });
 */
export function useGetObjectCardSubDriversQuery(baseOptions: Apollo.QueryHookOptions<GetObjectCardSubDriversQuery, GetObjectCardSubDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetObjectCardSubDriversQuery, GetObjectCardSubDriversQueryVariables>(GetObjectCardSubDriversDocument, options);
      }
export function useGetObjectCardSubDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObjectCardSubDriversQuery, GetObjectCardSubDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetObjectCardSubDriversQuery, GetObjectCardSubDriversQueryVariables>(GetObjectCardSubDriversDocument, options);
        }
export function useGetObjectCardSubDriversSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetObjectCardSubDriversQuery, GetObjectCardSubDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetObjectCardSubDriversQuery, GetObjectCardSubDriversQueryVariables>(GetObjectCardSubDriversDocument, options);
        }
export type GetObjectCardSubDriversQueryHookResult = ReturnType<typeof useGetObjectCardSubDriversQuery>;
export type GetObjectCardSubDriversLazyQueryHookResult = ReturnType<typeof useGetObjectCardSubDriversLazyQuery>;
export type GetObjectCardSubDriversSuspenseQueryHookResult = ReturnType<typeof useGetObjectCardSubDriversSuspenseQuery>;
export type GetObjectCardSubDriversQueryResult = Apollo.QueryResult<GetObjectCardSubDriversQuery, GetObjectCardSubDriversQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetObjectCardSubDriversQuery((req, res, ctx) => {
 *   const { modularObjectId, nameContains, pageInfoInput } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByIDs })
 *   )
 * })
 */
export const mockGetObjectCardSubDriversQuery = (resolver: Parameters<typeof graphql.query<GetObjectCardSubDriversQuery, GetObjectCardSubDriversQueryVariables>>[1]) =>
  graphql.query<GetObjectCardSubDriversQuery, GetObjectCardSubDriversQueryVariables>(
    'GetObjectCardSubDrivers',
    resolver
  )
