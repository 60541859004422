import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import useSearchableUserList from './hooks/useSearchableUserList/useSearchableUserList';

import { useGetUsersByEmailLazyQuery } from '@/components/common/Drivers/DriverSection/DriverListActions/AddCollaborators/GetUsersByEmail.query.generated';
import { useGetUsersByOrganizationQuery } from '@/components/Company/UserTable/getUsersByOrganization.generated';
import { GetCollaboratorListDocument, useGetCollaboratorListQuery } from './queries/getCollaboratorList.generated';

import type { ShareInput, Team, TeamShareInput, User } from '@/__generated__/types';
import { apolloClientSideClient } from '@/apollo/apolloClient';
import type { GetObjectCardObjectQuery } from '@/components/common/ModularObject/Card/GetObjectCardObject.generated';
import { useTeamsForDisplayQuery } from '@/components/SideNavigation/TabSections/Teams/TeamsForDisplay.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { GetLoggedInUserDocument } from '@/state/getLoggedInUser.generated';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useSendAnonymousUserExternalLinkEmailsMutation } from './mutations/addExternalLink.generated';
import { useAddNewCollaboratorsMutation } from './mutations/addShares.generated';

export const CollabDiscoveryContext = createContext<ReturnType<typeof useCollabDiscoveryContextApi>>(null);

export function useCollabDiscoveryContext () {
  const context = useContext(CollabDiscoveryContext);

  if (!context) {
    throw new Error('useCollabDiscoveryContext must be used within a CollabDiscoveryProvider');
  }

  return context;
}

interface CollabDiscoveryProviderProps {
  children: React.ReactNode;
  objectId: string;
}

const useCollabDiscoveryContextApi = (objectId: string) => {
  const [role, setRole] = useState('Viewer');
  const [externalUserAddition, setExternalUserAddition] = useState(false);
  const [externalTeamAddition, setExternalTeamAddition] = useState(false);

  const {
    setUsers,
    users,
    addedUsers,
    removeUser,
    addUser,
    setTeams,
    teams,
    addedTeams,
    addTeam,
    removeTeam,
  } = useSearchableUserList();

  const data = apolloClientSideClient.readQuery({
    query: GetLoggedInUserDocument,
  });

  const loggedInUser = data?.getUser?.user ?? {};

  useGetUsersByOrganizationQuery({
    fetchPolicy: 'network-only',
    variables: {
      orgID: loggedInUser?.organizationId,
    },
    onCompleted: (data) => {
      setUsers({
        userList: data.getUsersByOrganization,
      });
    },
  });

  useTeamsForDisplayQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTeams({
        teamList: data.teams.edges,
      });
    },
  });

  const [sendAnonymousUserExternalLinkEmails] = useSendAnonymousUserExternalLinkEmailsMutation();

  const [getUserByEmail] = useGetUsersByEmailLazyQuery({
    onCompleted: (data) => {
      const [externalUser] = data.getUsersByEmail;

      setUsers({
        userList: [
          ...users,
          {
            ...externalUser,
            isExternal: true,
          },
        ],
      });
    },
  });

  const { data: collaboratorData } = useGetCollaboratorListQuery({
    fetchPolicy: 'network-only',
    variables: {
      ids: [objectId],
    },
  });

  const collaborators = collaboratorData?.getModularObjectByIDs;

  const [{ owner = { id: '' }, shares = [], teamShares = [] } = {}] = collaborators ?? [];

  const collaboratorList = useMemo(() => {
    const anonymousUsers = collaborators?.[0]?.anonymousUsersSharedTo ?? [];
    return [...shares, ...anonymousUsers];
  }, [collaborators, shares]);

  const [addNewCollaborators] = useAddNewCollaboratorsMutation({
    update: (cache, { data }) => {
      const newCollaborators = data.addShares;
      const {
        getModularObjectByIDs: currentCollaborators,
      }: GetObjectCardObjectQuery = cache.readQuery({
        query: GetCollaboratorListDocument,
        variables: {
          ids: [objectId],
        },
      });

      cache.writeQuery({
        query: GetCollaboratorListDocument,
        variables: {
          ids: [objectId],
        },
        data: {
          getModularObjectByIDs: [
            {
              ...currentCollaborators[0],
              shares: [
                ...currentCollaborators[0].shares,
                ...newCollaborators,
              ],
            },
          ],
        },
      });
    },
  });

  const handleSubmitAnonymousUsers = useCallback(
    async (anonymousUserEmails: string[], objectId: string) => {
      try {
        await sendAnonymousUserExternalLinkEmails({
          variables: {
            input: anonymousUserEmails.map(email => ({
              emailAddress: email,
              objectID: objectId,
            })),
          },
          refetchQueries: [GetCollaboratorListDocument],
        });
        addToastSuccess('External link shared');
      } catch (error) {
        addToastError('Failed to share external link');
        console.error(error);
      }
    },
    [sendAnonymousUserExternalLinkEmails],
  );

  const handleSubmitSharedUsers = useCallback(async () => {
    try {
      // @ts-expect-error the types are lying, this is not a User type, it is a mutated version of it that has a few properties added to it that aren't typed.  Some of the objects also do not have the same properties as a User object as well
      if (addedUsers.some(user => user.isAnonymousUser)) {
        // @ts-expect-error the types are lying, this is not a User type, it is a mutated version of it that has a few properties added to it that aren't typed.  Some of the objects also do not have the same properties as a User object as well
        const anonymousUserEmails = addedUsers.filter(user => user.isAnonymousUser).map(user => user.email);
        try {
          const shouldNotSendCollaboratorRequest = anonymousUserEmails.length === addedUsers.length;
          await handleSubmitAnonymousUsers(anonymousUserEmails, objectId);
          if (shouldNotSendCollaboratorRequest) return;
        } catch (error) {}
      }
      // @ts-expect-error the types are lying, this is not a User type, it is a mutated version of it that has a few properties added to it that aren't typed.  Some of the objects also do not have the same properties as a User object as well
      const sharedUsers = addedUsers.filter(user => !user.isAnonymousUser).map((user: User) => {
        const { id, email, firstName, lastName, organizationId, organization, profileImage } = user;

        return {
          id: uuidv5(`${user?.id}-${uuidv4()}`, uuidv4()),
          externalType: 'modular_object',
          externalId: objectId,
          user: {
            organizationId: organizationId ?? organization?.id,
            id,
            email,
            firstName,
            lastName,
            profileImage,
            fullName: null,
            title: null,
          },
          userId: id,
          role,
        };
      });

      const sharedTeams = addedTeams.map(team => ({
        teamId: (team as Team).id,
        modularObjectId: objectId,
        defaultRole: role,
      }));

      await addNewCollaborators({
        variables: {
          input: {
            shares: sharedUsers as ShareInput[],
            allowPartialSuccess: false,
          },
          teamShares: sharedTeams as TeamShareInput[],
          externalLinkInput: [],
        },
        refetchQueries: [GetCollaboratorListDocument],
      });
      setUsers({ addedUsers: [] });
      setTeams({ addedTeams: [] });
      addToastSuccess('Collaborators added');
    } catch (error) {
      addToastError('Failed to add collaborators');
      console.error(error);
    }
  }, [addedUsers, addNewCollaborators, role, objectId, setUsers, setTeams, addedTeams]);

  const currentUserId = loggedInUser?.id;
  const currentUserIsOwner = Boolean(owner?.id === currentUserId);
  const currentUserRole = currentUserIsOwner
    ? 'Owner'
    : Object.values(shares).find(share => share.user?.id === currentUserId)?.role;

  const api = useMemo(() => ({
    users,
    addedUsers,
    teams,
    addedTeams,
    externalUserAddition,
    setExternalUserAddition,
    externalTeamAddition,
    setExternalTeamAddition,
    currentUserIsOwner,
    currentUserRole,
    currentUserId,
    setUsers,
    setRole,
    removeUser,
    removeTeam,
    addUser,
    addTeam,
    setTeams,
    objectOwner: owner,
    initialUsers: collaboratorList,
    initialTeams: teamShares,
    handleSubmitSharedUsers,
    getUserByEmail,
  }), [
    users,
    teams,
    addedUsers,
    addedTeams,
    setUsers,
    externalUserAddition,
    setExternalUserAddition,
    externalTeamAddition,
    setExternalTeamAddition,
    currentUserId,
    currentUserIsOwner,
    currentUserRole,
    collaboratorList,
    owner,
    handleSubmitSharedUsers,
    removeUser,
    removeTeam,
    addUser,
    addTeam,
    setTeams,
    getUserByEmail,
    teamShares,
  ]);

  return api;
};

export default function CollabDiscoveryProvider ({ children, objectId }: CollabDiscoveryProviderProps) {
  const api = useCollabDiscoveryContextApi(objectId);
  return (
    <CollabDiscoveryContext.Provider value={api}>
      {children}
    </CollabDiscoveryContext.Provider>
  );
}
