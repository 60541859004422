import { useEditContext } from '@/components';
import { CreateNewDriverModal } from '@/components/modals';
import SelectDriversToAddModal from '@/components/modals/AddDriverModal/SelectDriversToAddModal';
import { useModal } from '@/hooks/useModal';
import { useNestingMutations } from '@/hooks/useNestingMutations';
import { TemplateType } from '@/models/template.model';
import { useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { selectRootState } from '@/state/selectors';
interface AddMilestoneButtonProps {
  className?: string;
  hardwareId?: string;
}
export default function AddNewDriverButton (
  { hardwareId, className }: Readonly<AddMilestoneButtonProps>,
): JSX.Element {
  const { objectCardData } = useObjectCardContext();
  const isDriver = objectCardData?.template?.type === TemplateType.Driver;
  const { closeModal, showModal } = useModal();
  const { canUserEdit } = useEditContext();
  const { addChild } = useNestingMutations(hardwareId);

  const state = useAppSelector(selectRootState);
  const { modularObjects: modularObjectState, templates } = state;

  const builds = Object.values(modularObjectState);
  const drivers = builds.filter((object) => templates[object?.templateId]?.type === TemplateType.Driver);

  if (!canUserEdit) return null;
  return (
    <button
      type='button'
      className={`font-medium flex items-center btn-primary-hollow effra-xs px-4 py-3 justify-center gap-3 ${className}`}
      onClick={() => {
        drivers.length ?
          showModal(
            <SelectDriversToAddModal
              hardwareId={hardwareId}
              onSubmit={async (integrations) => {
                await addChild({
                  variables: {
                    input: integrations.map(({ parentId, objectId }) => ({
                      parentId,
                      objectId,
                    })),
                  },
                });
                closeModal();
              }}
            />,
            { isMegaModal: true, showCloseIcon: false },
          ) :
          showModal(
            <CreateNewDriverModal
              hardwareId={hardwareId}
            />,
            { isMegaModal: true, showCloseIcon: false },
          );
      }}
    >
      <span className='whitespace-nowrap truncate'>
        {isDriver ? 'Add Nested Driver' : 'Add Driver'}
      </span>
    </button>
  );
}
