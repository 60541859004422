import type { ModularObject } from '@/__generated__/types';
import { TemplateType } from '@/models/template.model';
import { useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { createContext, type PropsWithChildren, useContext, useMemo } from 'react';

interface DriverListContextType {
  hasSubDriversInDriverList: boolean;
  drivers: ModularObject[];
}

const DriverListContext = createContext<DriverListContextType>(null);

export function useDriverListContext (): Readonly<DriverListContextType> {
  const context = useContext(DriverListContext);

  if (context === undefined) {
    throw new Error('useDriverListContext must be used within a DriverListProvider');
  }

  return context;
}

export type DriversPendingApproval = ModularObject & { isPending?: boolean };

interface DriverListProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newIntegrations?: any;
  object: DriversPendingApproval;
}

export default function DriverListProvider ({
  children,
  newIntegrations = [],
  object,
}: Readonly<PropsWithChildren<DriverListProviderProps>>): JSX.Element {
  const { modularObjects, templates } = useAppSelector(selectRootState);
  const drivers = useMemo(() => object?.drivers?.edges?.map(({ node }) => node) ?? [], [object?.drivers]);
  const hasSubDriversInDriverList = useMemo(
    () => (drivers ?? []).some((driver) => Boolean(driver?.drivers?.edges.length)),
    [
      drivers,
    ],
  );

  const value = useMemo(() => ({ hasSubDriversInDriverList, drivers }), [hasSubDriversInDriverList, drivers]);

  newIntegrations.forEach((integration) => {
    // TODO: update to remove redux state lookup
    const modularObject = modularObjects[integration?.objectId];

    const driverIDs = drivers?.map((driver) => driver?.id);
    if (driverIDs?.includes(modularObject?.id)) return;

    const template = templates[modularObject?.templateId];
    if (template?.type !== TemplateType.Driver) return;
    const modularObjectWithTemplate = { ...modularObject, template, isPending: true };

    drivers.push(modularObjectWithTemplate);
  });

  return <DriverListContext.Provider value={value}>{children}</DriverListContext.Provider>;
}
