import type { DiffType, UpdateModularObjectInput } from '@/__generated__/types';

// TODO: Diff creation can be moved to the backend so that the frontend can send just the assigneeIds and driverIds
export function getUpdateAssigneePayload (assigneeId: string, driverIds: string[]): UpdateModularObjectInput {
  const diffs = driverIds.map((driverId) => ({
    diffType: 'updated' as DiffType,
    externalID: driverId,
    externalType: 'modular_object',
    diff: { to: { assigneeId }, displayNames: { assigneeId: 'assigneeId' } },
    fromMigration: false,
  }));

  return { diffs };
}
