import type { Placement } from '@popperjs/core';
import cx from 'classnames';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import Portal from '../Portal/Portal';

interface InfoTooltipProps {
  children: React.ReactNode;
  className?: string;
  tooltipClassName?: string;
  tooltipContentClassName?: string;
  delay?: number;
  message: string;
  placement?: Placement;
  disabled?: boolean;
}

const InfoTooltip = (
  {
    children,
    className,
    tooltipClassName,
    tooltipContentClassName,
    delay = 0.5,
    message,
    disabled,
    placement = 'auto',
  }: InfoTooltipProps,
): JSX.Element => {
  const [isShowingPopper, setIsShowingPopper] = useState(false);
  const [popperRefElement, setPopperRefElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: popperStyles, attributes } = usePopper(popperRefElement, popperElement, {
    placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 6] } },
    ],
  });

  const debouncedShowPopper = debounce((shouldShowPopper: boolean) => {
    setIsShowingPopper(shouldShowPopper);
  }, delay * 1000);

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <>
      <div
        className={className}
        ref={setPopperRefElement}
        onMouseOver={() => {
          debouncedShowPopper(true);
        }}
        onMouseLeave={() => {
          debouncedShowPopper(false);
          setIsShowingPopper(false);
        }}
      >
        {children}
      </div>
      {isShowingPopper ?
        (
          <Portal>
            <div
              data-testid='info-tooltip'
              ref={setPopperElement}
              style={{ zIndex: '50', ...popperStyles.popper }}
              {...attributes.popper}
              className={cx('info-tooltip-popper absolute', tooltipClassName)}
            >
              <div className={cx('flex items-start gap-2 p-4 bg-black', tooltipContentClassName)}>
                <div className='text-xs font-normal text-left text-white normal-case whitespace-nowrap tracking-[0.5px] max-w-[35ch]'>
                  {(message ?? '').split('\n').map((line, idx) => <p key={`info-tooltip-${idx}`}>{line}</p>)}
                </div>
              </div>

              <div
                ref={setArrowElement}
                style={popperStyles.arrow}
                className='bg-black info-tooltip-arrow'
              />
            </div>
          </Portal>
        ) :
        null}
    </>
  );
};

export default InfoTooltip;
