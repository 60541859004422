import type {
  CreateModularObjectCardFragmentFragment,
} from '@/components/common/CreateNewObjectCard/CreateModularObject.generated';
import { addToastError } from '@/components/Toast/utils';
import { useModal } from '@/hooks/useModal';
import { TemplateType } from '@/models/template.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { getUserTemplates } from '@/util/functions';
import metrics from '@/util/metrics';
import { createRef, type Ref, useEffect, useMemo, useState } from 'react';
import MegaModal from '../MegaModal/MegaModal';
import MegaModalEmptyList from '../MegaModal/MegaModalEmptyList/MegaModalEmptyList';
import MegaModalHeader from '../MegaModal/MegaModalHeader/MegaModalHeader';
import MegaModalSearchInput from '../MegaModal/MegaModalSearchInput/MegaModalSearchInput';
import CreateNewObjectList from './CreateNewObjectList/CreateNewObjectList';
import CreateNewObjectModalFilters from './CreateNewObjectModalFilters';
import { useGetTemplatesQuery } from './getTemplates.generated';

export enum Filter {
  All = 'all',
  Drivers = 'drivers',
  Builds = 'builds',
}

export interface CreateNewObjectModalProps {
  createdFromId?: string;
  createdFromTemplateId?: string;
  asParent?: boolean;
  isBlockingDependency?: boolean;
  filteredBy?: Filter;
  isDependency?: boolean;
  showFilters?: boolean;
  afterObjectCreationSave?: (modularObject: CreateModularObjectCardFragmentFragment) => void;
}

export default function CreateNewObjectModal ({
  createdFromId,
  createdFromTemplateId,
  isBlockingDependency = false,
  isDependency = false,
  asParent = false,
  filteredBy = Filter.All,
  showFilters = true,
  afterObjectCreationSave = null,
}: CreateNewObjectModalProps): JSX.Element {
  const { openCreateNewObjectCardDrawer } = useObjectCardContext();
  const { closeModal } = useModal();
  const inputRef: Ref<HTMLInputElement> = createRef();
  const [search, setSearch] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<Filter>(filteredBy);

  const { data, error } = useGetTemplatesQuery({
    variables: {
      input: {
        getOnlyUsedByModularObjects: false,
        byId: null,
      },
    },
  });

  const templates = useMemo(() => data?.getTemplates ?? [], [data]);

  if (error) {
    console.error(error);
    addToastError(error.message);
  }

  useEffect(() => {
    inputRef?.current?.focus();
    setSearch('');
  }, [open]);

  // Split templates into Company and Global
  const userTemplates = getUserTemplates(templates);

  // Create results list and filter templates based off search and filter pills
  const results = Object.keys(userTemplates).filter(id => {
    const template = userTemplates[id];
    // Don't show L-Minus Milestone template
    if (template?.id === 'd5ce3c7b-4776-47e0-aa14-796f3dd33784') {
      return false;
    }

    if (selectedFilter === Filter.Drivers && template?.type !== TemplateType.Driver) {
      return false;
    }

    if (selectedFilter === Filter.Builds && template?.type === TemplateType.Driver) {
      return false;
    }

    if (!search.length) {
      return true;
    }

    return template.name.toLowerCase().includes(search.toLowerCase());
  }).map((id) => userTemplates[id]);

  async function handleCreateClick (id: string, name: string): Promise<void> {
    metrics.track('mega modal - create new click', { template: name, templateId: id });

    closeModal();

    openCreateNewObjectCardDrawer({
      templateId: id,
      createdFromId,
      createdFromTemplateId,
      isBlockingDependency,
      isDependency,
      asParent,
      afterObjectCreationSave,
    });
  }

  const objectType = selectedFilter === Filter.Drivers
    ? TemplateType.Driver
    : selectedFilter === Filter.Builds
    ? TemplateType.Build
    : 'item';

  return (
    <MegaModal className='h-[80vh]'>
      <MegaModalHeader
        title='Create something new'
        subtext={`In Integrate, ${objectType}s are created from templates. Search the list below for the ${objectType} you want.`}
      />
      <div className='flex flex-col gap-6 min-h-0 grow'>
        <MegaModalSearchInput
          value={search}
          ref={inputRef}
          onChange={e => {
            setSearch(e.target.value);
            setSubmitted(false);
          }}
          placeholder='Search'
        />
        {showFilters && (
          <CreateNewObjectModalFilters
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        )}
        <div className='flex flex-col gap-2 min-h-0 grow'>
          <div className='flex overflow-auto overflow-y-auto flex-col flex-1 no-scrollbar'>
            {!results.length
              ? (
                <MegaModalEmptyList
                  message={submitted
                    ? `Request sent to Integrate admins for consideration.\nIn the meantime, browse our other available ${objectType}s.`
                    : `No ${objectType}s match your search.`}
                  buttonText={submitted ? 'Clear search' : `Request this ${objectType}`}
                  onClick={() => {
                    metrics.track(`mega modal - request new ${objectType}`, { templateName: search });

                    if (submitted) {
                      setSubmitted(false);
                      setSearch('');
                      return;
                    }

                    setSubmitted(true);
                  }}
                />
              )
              : <CreateNewObjectList results={results} onCreateClick={handleCreateClick} />}
          </div>
        </div>
      </div>
    </MegaModal>
  );
}
