import type { TeamMember } from '@/__generated__/types';
import CollaboratorsBadge from '@/components/Schedule/Timeline/Gantt/CollaboratorsBadge';
import TeamProfileImage from '@/components/TeamProfileImage/TeamProfileImage';

export default function Team (
  { team }: {
    team: { name: string; teamMembers: TeamMember[]; internalTeamMemberCount: number; externalTeamMemberCount: number };
  },
) {
  return (
    <div className='flex gap-2 items-center w-full'>
      <div className='w-[40px]'>
        <TeamProfileImage />
      </div>
      <div className='flex flex-col'>
        <div className='truncate'>{team.name}</div>
        <div className='flex gap-2'>
          {Boolean(team.internalTeamMemberCount) && (
            <CollaboratorsBadge nCollaborators={team.internalTeamMemberCount} type='internal' />
          )}
          {Boolean(team.externalTeamMemberCount) && (
            <CollaboratorsBadge nCollaborators={team.externalTeamMemberCount} type='external' />
          )}
        </div>
      </div>
    </div>
  );
}
