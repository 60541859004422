export enum DriverStatus {
  ToDo = 'To Do',
  InProgress = 'In Progress',
  Complete = 'Complete',
  Waived = 'Waived',
}

export const DriverStatusOptions = Object.values(DriverStatus);

export enum DriverPillColor {
  Black = 'black',
  White = 'white',
  Teal = 'teal',
  Purple = 'purple',
  Gold = 'gold',
}

export function isValidDriverStatus (status: string): boolean {
  if (!status) return false;
  return DriverStatusOptions.some(validStatus =>
    validStatus.toLowerCase().replace(' ', '') === status.toLowerCase().replace(' ', '')
  );
}

export function mapToValidDriverStatus (status: string): string {
  // Try to map the string to a valid driver status
  for (const validStatus of DriverStatusOptions) {
    if (status.toLowerCase().replace(' ', '') === validStatus.toLowerCase().replace(' ', '')) {
      return validStatus;
    }
  }
  return DriverStatus.ToDo;
}
