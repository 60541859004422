import HeaderName from '@/components/common/Drivers/DriverSection/HeaderName';
import type { DriverFragment } from '@/components/Schedule/queries/driverFragment.generated';
import cx from 'classnames';
import { createRef, type Ref, useEffect, useState } from 'react';
import SelectDrivers from './SelectDrivers';

interface SelectDriversContainerProps {
  handleSelect: (selected: string[]) => void;
  drivers: DriverFragment[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedDrivers: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedDrivers: any;
}

export default function SelectDriversContainer (
  { drivers, setSelectedDrivers, selectedDrivers, handleSelect }: Readonly<SelectDriversContainerProps>,
): JSX.Element {
  const tableRef: Ref<HTMLDivElement> = createRef();
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    const tableWrapper = tableRef.current;
    if (tableWrapper?.scrollHeight > tableWrapper?.clientHeight) {
      setHasScrollbar(true);
    } else {
      setHasScrollbar(false);
    }
  }, []);
  const handleCheckAll = (event): void => {
    const { checked } = event.target;
    if (checked) {
      const allDriverIDs = Object.values(drivers).map((item) => item.id);
      setSelectedDrivers(allDriverIDs);
    } else {
      setSelectedDrivers([]);
    }
  };
  return (
    <>
      <header
        className={cx('flex flex-start text-black mt-[24px]', {
          'pr-5': hasScrollbar,
        })}
      >
        <div className='flex flex-row items-center min-w-0 flex-[2] gap-[8px]'>
          <div className='flex flex-[2] gap-[8px]'>
            <HeaderName
              className='flex shrink'
              text={
                <input
                  type='checkbox'
                  onChange={handleCheckAll}
                  className='input-checkbox custom-checkbox checked:bg-primary'
                />
              }
            />
            <HeaderName
              className='flex-1'
              text='name'
            />
          </div>
          <HeaderName
            className='flex-1'
            text='type'
          />
          <HeaderName
            className='flex-[2]'
            text='Parent'
          />
        </div>
        <div className='flex flex-1 min-w-0 gap-[8px]'>
          <HeaderName
            className='flex-1'
            text='permission'
          />
          <HeaderName
            className='flex-[2]'
            text='owner'
          />
          <HeaderName
            className='flex-1'
            text='company'
          />
        </div>
      </header>
      <SelectDrivers
        handleSelect={handleSelect}
        tableRef={tableRef}
        drivers={drivers}
        selectedDrivers={selectedDrivers}
      />
    </>
  );
}
