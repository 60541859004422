import { Listbox } from '@headlessui/react';
import cx from 'classnames';
import { forwardRef } from 'react';
import SelectIcon from './SelectIcon';

interface SelectBoxProps extends React.ComponentProps<typeof Listbox.Button> {
  name?: string;
  placeholder?: string;
  selectedItem: string | null;
  ref: React.Ref<HTMLButtonElement>;
  buttonClassName?: string;
  className?: string;
}

const SelectBoxButton = forwardRef(function SelectBoxButton (props: SelectBoxProps, ref: React.Ref<HTMLButtonElement>) {
  const { selectedItem, buttonClassName, className, ...rest } = props;
  const placeholder = props?.placeholder ?
    props?.placeholder
    : props?.name
    ? `Select ${props.name}`
    : 'Select an item';
  return (
    <Listbox.Button
      className={cx(
        'listbox-button',
        {
          'border-transparent': selectedItem,
        },
        buttonClassName,
      )}
      ref={ref}
      {...rest}
    >
      <span
        className={cx('block', className, {
          'text-neutral-400': !selectedItem,
        })}
      >
        {selectedItem || placeholder}
      </span>
      <SelectIcon />
    </Listbox.Button>
  );
});

export default SelectBoxButton;
