import type { User } from '@/__generated__/types';
import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { KeyboardEventNames, THEME_COLORS } from '@/util/constants';
import cx from 'classnames';
import { type RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { InputWithPills } from './EntitySearchBar/InputWithPills/InputWithPills';
import useClickAway from './hooks/useClickAway';
import { KeyboardNavigableUserList } from './KeyboardNavigableUserList';

interface UserSearchBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUsers: (users: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addedUsers: any[];
  removeUser: (userId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addUser: (user: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserByEmail?: (options: any) => Promise<any>;
  userList?: typeof KeyboardNavigableUserList;
  userPill?: typeof UserPill;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialUsers?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectOwner?: any;
  disableExternalUsers?: boolean;
  disableAnonymousUsers?: boolean;
  singleUser?: boolean;
}

export default function UserSearchBar ({
  setUsers,
  users,
  addedUsers,
  objectOwner,
  initialUsers,
  removeUser,
  addUser,
  getUserByEmail,
  userList = KeyboardNavigableUserList,
  placeholder = 'Add people or emails...',
  disableExternalUsers = false,
  disableAnonymousUsers = true,
  singleUser = false,
  userPill,
}: UserSearchBarProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showList, setShowList] = useState(false);

  const inputRef = useRef(null);
  const inputIconRef = useRef(null);
  const lastUserAddedRef = useRef(null);
  const userDisplayRef = useRef(null);

  const userPillComponent = userPill ?? (singleUser ? SingleSelectedUser : UserPill);

  const containerRef = useClickAway(() => {
    setShowList(false);
  });

  function handleSetOrRemoveUsers (userToRemove) {
    if (userToRemove?.isExternal) {
      setUsers({
        addedUsers: addedUsers.filter(user => user.id !== userToRemove.id),
        userList: users.filter(user => user.id !== userToRemove.id),
      });
      inputRef.current.focus();
    } else {
      removeUser(userToRemove.id);
      inputRef.current.focus();
    }
  }

  function handleFocusedUserPill (e) {
    const isValidKey = e.key === KeyboardEventNames.Backspace || e.key === KeyboardEventNames.Enter;

    if (isValidKey && lastUserAddedRef.current === document.activeElement) {
      handleSetOrRemoveUsers(addedUsers.at(-1));
    }
  }

  async function handleInputKeydown (e) {
    if (
      e.key === KeyboardEventNames.Backspace && searchTerm === '' &&
      lastUserAddedRef.current !== document.activeElement
    ) {
      e.preventDefault();
      lastUserAddedRef.current?.focus();
    }

    if (e.key === KeyboardEventNames.Enter && searchTerm !== '') {
      e.preventDefault();
      const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value.trim());
      const isInternalUser = Boolean(users.find(user => user.email === e.target.value));
      const isAlreadyAdded = Boolean(addedUsers.find(
        addedUser => addedUser.email === e.target.value,
      ));

      if (isValidEmail && !isInternalUser && !isAlreadyAdded && !disableExternalUsers) {
        fetchUserByEmail(e.target.value);
      }
      inputRef.current.focus();
    }
  }

  function handleInputFocus () {
    if (userDisplayRef.current && inputIconRef.current) {
      userDisplayRef.current.style.borderBottom = `1px solid ${THEME_COLORS.primary.DEFAULT}`;
      inputIconRef.current.style.color = 'black';
    }
    setShowList(true);
  }

  const fetchUserByEmail = useCallback(
    async emailAddress => {
      const res = await getUserByEmail({
        variables: {
          emails: [emailAddress],
        },
        onCompleted: (data) => {
          const externalUser = {
            ...data.getUsersByEmail[0],
            isExternal: true,
          };

          // Add the user to the options list
          addUser(externalUser);
          setSearchTerm('');
          // Hide the dropdown
          setShowList(false);
        },
      });

      if (res?.data?.getUsersByEmail?.length === 0 && !disableAnonymousUsers) {
        addUser({
          fullName: emailAddress,
          email: emailAddress,
          isAnonymousUser: true,
        });

        setSearchTerm('');
      }
    },
    [addUser, disableAnonymousUsers, getUserByEmail],
  );

  async function handleInputChange (e) {
    setSearchTerm(e.target.value);

    if (e.target.value === '') {
      const internalUsers = users.filter(user => !user.isExternal);
      setUsers((prev) => ({ ...prev, userList: internalUsers }));
    }
  }

  function handleInputBlur () {
    if (userDisplayRef.current && inputIconRef.current) {
      userDisplayRef.current.style.borderBottom = '1px solid #cccccc';
      inputIconRef.current.style.color = THEME_COLORS.gray['70'];
    }

    const emailInput = searchTerm;

    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailInput.trim());
    const isInternalUser = Boolean(users.find(user => user.email === emailInput));
    const isAlreadyAdded = Boolean(addedUsers.find(
      addedUser => addedUser.email === emailInput,
    ));

    if (isValidEmail && !isInternalUser && !isAlreadyAdded && !disableExternalUsers) {
      fetchUserByEmail(emailInput);
    }
  }

  const filteredUsers = useMemo(() => {
    const filtered = users.filter(user => {
      const trimmed = searchTerm?.trim()?.toLowerCase() ?? '';
      const term = user.isExternal ? trimmed.slice(0, trimmed.indexOf('@')) : trimmed;
      const userIsOwner = objectOwner?.id === user.id;

      const isAlreadyAdded = Boolean(addedUsers.find(
        addedUser => Boolean(addedUser.id === user.id),
      ));

      const isAlreadyACollaborator = Boolean(initialUsers.find(
        collaborator => collaborator?.user?.id === user?.id,
      ));
      const termMatchesUserName = user.fullName?.toLowerCase().includes(term);
      const termMatchesUserEmail = user?.email?.toLowerCase().includes(term);

      return (termMatchesUserEmail || termMatchesUserName) && !isAlreadyAdded && !userIsOwner &&
        !isAlreadyACollaborator;
    });

    return filtered;
  }, [users, searchTerm, addedUsers, initialUsers, objectOwner]);

  const handleAddUser = useCallback((user) => {
    addUser(user);
    setSearchTerm('');
    inputRef.current.focus();
  }, [addUser]);

  const hasAnonymousUsers = addedUsers.some(user => user.isAnonymousUser);

  return (
    <div ref={containerRef} className='relative grow'>
      <div
        ref={userDisplayRef}
        className={cx(
          'flex gap-2 items-center p-2 w-full bg-white border resize-none border-gray-80 focus:border-b-primary:outline-none',
          {
            'flex-wrap': !singleUser,
          },
        )}
      >
        <InputWithPills
          ref={inputRef}
          placeholder={placeholder}
          className='flex gap-2 bg-transparent focus:outline-none grow placeholder:text-neutral-400'
          inputClassName={cx('bg-transparent focus:outline-none placeholder:text-neutral-400', {
            'grow': !singleUser || !addedUsers.length,
            'w-0': singleUser && Boolean(addedUsers.length),
          })}
          onInputFocus={handleInputFocus}
          onInputBlur={handleInputBlur}
          searchTerm={searchTerm}
          onInputChange={handleInputChange}
          onSearch={handleInputKeydown}
        >
          {addedUsers.map((user, idx, array) =>
            userPillComponent({
              user,
              handleSetOrRemoveUsers,
              handleFocusedUserPill,
              ref: idx === array.length - 1 ? lastUserAddedRef : null,
            })
          )}
        </InputWithPills>
      </div>
      <div className='absolute z-10 w-full'>
        {userList({
          users: filteredUsers,
          addUser: handleAddUser,
          shouldShow: showList,
          shouldShowAnonymousUserMessage: hasAnonymousUsers,
        })}
      </div>
    </div>
  );
}

function UserPill (
  { user, handleSetOrRemoveUsers, handleFocusedUserPill, ref }: {
    user: User & { isExternal: boolean };
    handleSetOrRemoveUsers: (user: User) => void;
    handleFocusedUserPill: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
    ref: RefObject<HTMLButtonElement>;
  },
) {
  return (
    <button
      key={user?.id}
      ref={ref}
      className={cx(
        'flex items-center gap-1 py-[2px] px-2 border rounded-3xl whitespace-nowrap focus:text-white focus:outline-none hover:bg-gray-98 group',
        {
          'border-primary focus:bg-primary': !user.isExternal,
          'border-tertiary focus:bg-tertiary': user.isExternal,
        },
      )}
      onKeyDown={handleFocusedUserPill}
      onClick={() => {
        handleSetOrRemoveUsers(user);
      }}
    >
      <span className='effra-12'>{user.fullName}</span>
      <span className='pt-[1px]'>
        <i className='group-hover:text-black group-focus:text-white fa-regular fa-sharp fa-xmark text-gray-60' />
      </span>
    </button>
  );
}

function SingleSelectedUser (
  { user, handleSetOrRemoveUsers, handleFocusedUserPill, ref }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user: User & { isExternal: boolean };
    handleSetOrRemoveUsers: (user: User) => void;
    handleFocusedUserPill: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
    ref: RefObject<HTMLButtonElement>;
  },
) {
  return (
    <button
      key={user?.id}
      ref={ref}
      className={cx(
        'flex items-center gap-1 py-[2px] px-2 whitespace-nowrap outline-none grow justify-between w-full hover:bg-gray-98 group',
        {
          'text-primary': !user.isExternal,
          'text-tertiary': user.isExternal,
        },
      )}
      onKeyDown={handleFocusedUserPill}
      onClick={() => {
        handleSetOrRemoveUsers(user);
      }}
    >
      <span className='effra-12 flex gap-2'>
        <ProfileImage user={user} size='xsmall' />
        <div className='font-medium'>{user.fullName}</div>
        <div className='text-gray-60'>{user.email}</div>
      </span>
      <span className='pt-[1px]'>
        <i className='fa-regular fa-sharp fa-xmark text-gray-60 group-hover:text-black' />
      </span>
    </button>
  );
}
