import type { ModularObject } from '@/__generated__/types';
import DriverListRow from '@/components/common/Drivers/DriverSection/DriverListRow/DriverListRow';
import type { ObjectCardSubDriverFragment } from './getObjectCardSubDrivers.generated';
import useFilteredDrivers from './hooks/useFilteredDrivers';

interface DriverListProps {
  filterTerm: string;
  startDateFilter: string;
  targetDateFilter: string;
  parentFilter: string;
  drivers: ObjectCardSubDriverFragment[];
  setOpenDriver: (open: boolean) => void;
  setCurrentDriver: (driver: ModularObject) => void;
}

export default function DriverList ({
  filterTerm,
  drivers,
  startDateFilter,
  targetDateFilter,
  parentFilter,
}: Readonly<DriverListProps>): JSX.Element {
  const filteredDrivers = useFilteredDrivers(
    drivers,
    startDateFilter,
    targetDateFilter,
    parentFilter,
  );

  return (
    <div className='flex overflow-auto flex-col py-4 no-scrollbar grow' data-testid='driver-list'>
      {filteredDrivers
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((driver: ModularObject & { parent: Record<string, any> }, idx) => (
          <DriverListRow key={`${driver.id}-${idx}`} driver={driver} />
        ))}
    </div>
  );
}
