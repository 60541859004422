import type { User } from '@/__generated__/types';
import DriverPill from '@/components/Pill/DriverPill/DriverPill';
import DriverParent from '@/components/Schedule/AllDrivers/driverItems/DriverParent';
import DriverName from '@/components/Schedule/Builds/BuildListItem/buildListItems/DriverName';
import PermissionDeprecated from '@/components/Schedule/Builds/BuildListItem/buildListItems/UserPermissionOnObject';
import type { DriverFragment } from '@/components/Schedule/queries/driverFragment.generated';
import { useGetUserByIdQuery } from '@/components/User/getUserById.generated';
import UserComponent from '@/components/User/User';
import cx from 'classnames';

interface SelectDriverProps {
  selectedDrivers: string[];
  driver: DriverFragment & { Status?: string } & { selected?: boolean };
  onChange: (e) => void;
}
export default function SelectDriver ({ selectedDrivers, driver, onChange }: Readonly<SelectDriverProps>): JSX.Element {
  // TODO: This really needs to not be on a per-row basis.  We should be getting this via the driver
  const { data: ownerInfo } = useGetUserByIdQuery({ variables: { id: driver?.ownerId }, skip: !driver?.ownerId });
  const owner = ownerInfo?.getUserByID;
  const company = owner?.organization?.name;

  return (
    <div className='flex items-center py-2'>
      <div className='flex flex-row items-center min-w-0 flex-[2] gap-[8px]'>
        <div className='flex min-w-0 flex-[2] gap-[8px]'>
          <input
            name='selectedDrivers'
            onChange={onChange}
            value={driver?.id}
            className={`input-checkbox custom-checkbox checked:bg-primary`}
            type='checkbox'
            checked={selectedDrivers?.includes(driver.id)}
          />
          <DriverName object={driver} className='flex-1 min-w-0 font-medium truncate' />
        </div>
        <div className='flex flex-1'>
          <DriverPill
            type={driver?.template?.name}
            data={{
              assignee: driver?.assigneeId,
            }}
          />
        </div>
        <div className='flex min-w-0 flex-[2]'>
          <DriverParent driver={driver} />
        </div>
      </div>
      <div className='flex flex-1 min-w-0 gap-[8px]'>
        <div className='flex flex-1'>
          <PermissionDeprecated data={driver} isBlackFont />
        </div>
        {/* Owner */}
        <div
          className={cx('flex flex-[2] gap-2  min-w-0', {
            'opacity-50': driver?.Status === 'Complete' && driver?.assigneeId,
          })}
        >
          <UserComponent user={owner as Partial<User>} noRole={true} size='small' />
        </div>
        {/* Company */}
        <div className='flex flex-1'>{company}</div>
      </div>
    </div>
  );
}
