import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { KeyboardEventNames, THEME_COLORS } from '@/util/constants';
import cx from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import useClickAway from '../hooks/useClickAway';
import { NoResultsMessage } from '../KeyboardNavigableUserList';
import { InputWithPills } from './InputWithPills/InputWithPills';

import CollaboratorsBadge from '@/components/Schedule/Timeline/Gantt/CollaboratorsBadge';
import TeamProfileImage from '@/components/TeamProfileImage/TeamProfileImage';
import { InputPill } from './InputWithPills/InputPill';
import { KeyboardNavigableList, KeyboardNavigableListItem } from './KeyboardNavigableList';

interface EntitySearchBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  teams: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialUsers?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialTeams?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addedUsers: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addedTeams: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addUser: (user: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addTeam: (team: any) => void;
  removeUser: (userId: string) => void;
  removeTeam: (teamId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectOwner?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUsers: (users: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserByEmail?: (options: any) => Promise<any>;
  entityList?: typeof KeyboardNavigableList;
  placeholder?: string;
  disableExternalUsers?: boolean;
  disableAnonymousUsers?: boolean;
}

export default function EntitySearchBar ({
  setUsers,
  users,
  teams,
  addedUsers,
  addedTeams,
  objectOwner,
  initialUsers = [],
  initialTeams = [],
  removeUser,
  removeTeam,
  addUser,
  addTeam,
  getUserByEmail,
  disableAnonymousUsers = true,
  disableExternalUsers = true,
}: EntitySearchBarProps) {
  const [shouldShowList, setShowList] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const userDisplayRef = useRef(null);
  const inputRef = useRef(null);
  const inputIconRef = useRef(null);
  const lastEntityAddedRef = useRef(null);
  const containerRef = useClickAway(() => {
    setShowList(false);
  });

  const handleSearch = (e) => {
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value.trim());
    const isInternalUser = Boolean(users.find(user => user.email === e.target.value));
    const isAlreadyAdded = Boolean(addedUsers.find(
      addedUser => addedUser.email === e.target.value,
    ));

    if (isValidEmail && !isInternalUser && !isAlreadyAdded && !disableExternalUsers) {
      void fetchUserByEmail(e.target.value);
    }
  };

  async function handleInputChange (e) {
    setSearchTerm(e.target.value);

    if (e.target.value === '') {
      const internalUsers = users.filter(user => !user.isExternal);
      setUsers((prev) => ({ ...prev, userList: internalUsers }));
    }
  }

  function handleInputFocus () {
    if (userDisplayRef.current && inputIconRef.current) {
      userDisplayRef.current.style.borderBottom = `1px solid ${THEME_COLORS.primary.DEFAULT}`;
      inputIconRef.current.style.color = 'black';
    }
    setShowList(true);
  }

  function handleInputBlur () {
    if (userDisplayRef.current && inputIconRef.current) {
      userDisplayRef.current.style.borderBottom = '1px solid #cccccc';
      inputIconRef.current.style.color = THEME_COLORS.gray['70'];
    }

    const emailInput = searchTerm;

    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailInput.trim());
    const isInternalUser = Boolean(users.find(user => user.email === emailInput));
    const isAlreadyAdded = Boolean(addedUsers.find(
      addedUser => addedUser.email === emailInput,
    ));

    if (isValidEmail && !isInternalUser && !isAlreadyAdded && !disableExternalUsers) {
      fetchUserByEmail(emailInput);
    }
  }

  function handleSetOrRemoveUsers (userToRemove) {
    if (userToRemove?.isExternal) {
      setUsers({
        addedUsers: addedUsers.filter(user => user.id !== userToRemove.id),
        userList: users.filter(user => user.id !== userToRemove.id),
      });
      inputRef.current.focus();
    } else {
      removeUser(userToRemove.id);
      inputRef.current.focus();
    }
  }

  function handleSetOrRemoveTeams (teamToRemove) {
    removeTeam(teamToRemove.id);
    inputRef.current.focus();
  }

  function handleFocusedUserPill (e) {
    const isValidKey = e.key === KeyboardEventNames.Backspace || e.key === KeyboardEventNames.Enter;

    if (isValidKey && lastEntityAddedRef.current === document.activeElement) {
      handleSetOrRemoveUsers(addedUsers.at(-1));
    }
  }

  const fetchUserByEmail = useCallback(
    async emailAddress => {
      const res = await getUserByEmail({
        variables: {
          emails: [emailAddress],
        },
      });

      if (res?.data?.getUsersByEmail?.length === 0 && !disableAnonymousUsers) {
        addUser({
          fullName: emailAddress,
          email: emailAddress,
          isAnonymousUser: true,
        });

        setSearchTerm('');
      }
    },
    [addUser, disableAnonymousUsers, getUserByEmail],
  );

  const filteredUsers = useMemo(() => {
    const filtered = users.filter(user => {
      const trimmed = searchTerm?.trim()?.toLowerCase() ?? '';
      const term = user.isExternal ? trimmed.slice(0, trimmed.indexOf('@')) : trimmed;
      const userIsOwner = objectOwner?.id === user.id;

      const isAlreadyAdded = Boolean(addedUsers.find(
        addedUser => Boolean(addedUser.id === user.id),
      ));

      const isAlreadyACollaborator = Boolean(initialUsers.find(
        collaborator => collaborator?.user?.id === user?.id,
      ));
      const termMatchesUserName = user.fullName?.toLowerCase().includes(term);
      const termMatchesUserEmail = user?.email?.toLowerCase().includes(term);

      return (termMatchesUserEmail || termMatchesUserName) && !isAlreadyAdded && !userIsOwner &&
        !isAlreadyACollaborator;
    });

    return filtered;
  }, [users, searchTerm, addedUsers, initialUsers, objectOwner]);

  const filteredTeams = useMemo(() => {
    const filtered = teams?.filter(team => {
      const term = searchTerm?.trim()?.toLowerCase() ?? '';

      const isAlreadyAdded = Boolean(addedTeams.find(
        addedTeam => addedTeam.id === team.id,
      ));

      const isAlreadyACollaborator = Boolean(initialTeams.find(
        collaborator => collaborator?.team?.id === team?.id,
      ));

      const termMatchesTeamName = team.name?.toLowerCase().includes(term);

      return termMatchesTeamName && !isAlreadyAdded && !isAlreadyACollaborator;
    });

    return filtered;
  }, [teams, searchTerm, addedTeams, initialTeams]);

  const handleAddUser = useCallback((user) => {
    addUser(user);
    setSearchTerm('');
    inputRef.current.focus();
  }, [addUser]);

  const handleAddTeam = useCallback((team) => {
    addTeam(team);
    setSearchTerm('');
    inputRef.current.focus();
  }, [addTeam]);

  const hasAnonymousUsers = useMemo(() => addedUsers.some(user => user.isAnonymousUser), [addedUsers]);

  return (
    <div ref={containerRef} className='relative grow'>
      <InputWithPills
        ref={inputRef}
        searchTerm={searchTerm}
        onSearch={handleSearch}
        onInputFocus={handleInputFocus}
        onInputBlur={handleInputBlur}
        onInputChange={handleInputChange}
      >
        {addedUsers.map((user, idx, array) => (
          <InputPill
            key={user?.id}
            ref={(idx === array.length - 1 && !addedTeams.length) ? lastEntityAddedRef : null}
            className={cx(
              'flex items-center gap-1 py-[2px] px-2 border rounded-3xl whitespace-nowrap focus:text-white focus:outline-none hover:bg-gray-98 group',
              {
                'border-primary focus:bg-primary': !user.isExternal && !user.isAnonymousUser,
                'border-tertiary focus:bg-tertiary': user.isExternal,
                'border-black focus:bg-black': user.isAnonymousUser,
              },
            )}
            onKeyDown={handleFocusedUserPill}
            onClick={() => {
              handleSetOrRemoveUsers(user);
            }}
          >
            <span className='effra-12'>{user.fullName}</span>
            <span className='pt-[1px]'>
              <i className='group-hover:text-black group-focus:text-white fa-regular fa-sharp fa-xmark text-gray-60' />
            </span>
          </InputPill>
        ))}
        {addedTeams.map((team, idx, array) => (
          <InputPill
            key={team.id}
            ref={(idx === array.length - 1) ? lastEntityAddedRef : null}
            className='flex gap-1 items-center px-2 whitespace-nowrap rounded-3xl border border-black focus:text-white focus:outline-none py-[2px] group hover:bg-gray-98'
            onKeyDown={handleFocusedUserPill}
            onClick={() => {
              handleSetOrRemoveTeams(team);
            }}
          >
            <span className='effra-12'>{team.name}</span>
            <span className='pt-[1px]'>
              <i className='group-hover:text-black group-focus:text-white fa-regular fa-sharp fa-xmark text-gray-60' />
            </span>
          </InputPill>
        ))}
      </InputWithPills>
      <div className='absolute z-10 w-full'>
        <KeyboardNavigableList
          shouldShow={shouldShowList}
          noResultsMessage={NoResultsMessage}
          shouldShowAnonymousUserMessage={hasAnonymousUsers}
        >
          {filteredUsers.map(user => (
            <KeyboardNavigableListItem
              key={user.id}
              sortBy={user.fullName}
              onSelect={() => handleAddUser(user)}
              className={cx(
                'w-full gap-2 p-2 cursor-pointer effra-12 focus:bg-gray-200 focus:outline-none hover:bg-gray-200',
                {
                  'text-primary': !user.isExternal && !user.isAnonymousUser,
                  'text-tertiary': user.isExternal,
                  'text-black': user.isAnonymousUser,
                },
              )}
            >
              <span className='flex gap-4 items-center text-left effra-12'>
                <ProfileImage user={user} size='small' />
                <span className='flex flex-col'>
                  <p>{user.fullName}</p>
                  <p className='text-neutral-400 text-[10px]'>{user.email}</p>
                </span>
              </span>
            </KeyboardNavigableListItem>
          ))}
          {filteredTeams.map(team => (
            <KeyboardNavigableListItem
              key={team.id}
              sortBy={team.name}
              onSelect={() => handleAddTeam(team)}
              className={cx(
                'w-full gap-2 p-2 cursor-pointer effra-12 text-black focus:bg-gray-200 focus:outline-none hover:bg-gray-200',
              )}
            >
              <span className='flex gap-4 items-center text-left effra-12 h-[32px]'>
                <div className='w-[24px]'>
                  <TeamProfileImage />
                </div>
                <div className='flex gap-[6px]'>
                  <p className='max-w-[50%]truncate'>{team.name}</p>
                  <p className='text-gray-60'>Team</p>
                  {Boolean(team.internalTeamMemberCount) && (
                    <CollaboratorsBadge nCollaborators={team.internalTeamMemberCount} type='internal' />
                  )}
                  {Boolean(team.externalTeamMemberCount) && (
                    <CollaboratorsBadge nCollaborators={team.externalTeamMemberCount} type='external' />
                  )}
                </div>
              </span>
            </KeyboardNavigableListItem>
          ))}
        </KeyboardNavigableList>
      </div>
    </div>
  );
}
