import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetWorkloadQueryVariables = Types.Exact<{
  timeZone: Types.Scalars['String']['input'];
  timeScale: Types.InputMaybe<Types.Scalars['String']['input']>;
  filters: Types.InputMaybe<Types.DriverFilters>;
}>;


export type GetWorkloadQuery = { __typename?: 'Query', getWorkload: { __typename?: 'WorkloadResponse', capacity: number, workloads: Array<{ __typename?: 'Workload', from: any, workload: number | null, externalWorkload: number | null, remainingCapacity: number | null, overCapacity: number | null }> | null } | null };


export const GetWorkloadDocument = gql`
    query GetWorkload($timeZone: String!, $timeScale: String, $filters: DriverFilters) {
  getWorkload(timeZone: $timeZone, filters: $filters, timeScale: $timeScale) {
    workloads {
      from
      workload
      externalWorkload
      remainingCapacity
      overCapacity
    }
    capacity
  }
}
    `;

/**
 * __useGetWorkloadQuery__
 *
 * To run a query within a React component, call `useGetWorkloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkloadQuery({
 *   variables: {
 *      timeZone: // value for 'timeZone'
 *      timeScale: // value for 'timeScale'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetWorkloadQuery(baseOptions: Apollo.QueryHookOptions<GetWorkloadQuery, GetWorkloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkloadQuery, GetWorkloadQueryVariables>(GetWorkloadDocument, options);
      }
export function useGetWorkloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkloadQuery, GetWorkloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkloadQuery, GetWorkloadQueryVariables>(GetWorkloadDocument, options);
        }
export function useGetWorkloadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkloadQuery, GetWorkloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWorkloadQuery, GetWorkloadQueryVariables>(GetWorkloadDocument, options);
        }
export type GetWorkloadQueryHookResult = ReturnType<typeof useGetWorkloadQuery>;
export type GetWorkloadLazyQueryHookResult = ReturnType<typeof useGetWorkloadLazyQuery>;
export type GetWorkloadSuspenseQueryHookResult = ReturnType<typeof useGetWorkloadSuspenseQuery>;
export type GetWorkloadQueryResult = Apollo.QueryResult<GetWorkloadQuery, GetWorkloadQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetWorkloadQuery((req, res, ctx) => {
 *   const { timeZone, timeScale, filters } = req.variables;
 *   return res(
 *     ctx.data({ getWorkload })
 *   )
 * })
 */
export const mockGetWorkloadQuery = (resolver: Parameters<typeof graphql.query<GetWorkloadQuery, GetWorkloadQueryVariables>>[1]) =>
  graphql.query<GetWorkloadQuery, GetWorkloadQueryVariables>(
    'GetWorkload',
    resolver
  )
