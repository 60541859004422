import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFileArrowUp } from '@fortawesome/sharp-regular-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AddNewDriverButton from './AddNewDriverButton';
import { useListActionContext } from './ListActions.context';

interface DriverColumnHeaderProps {
  setFilterTerm: (term: string) => void;
  handleExportDrivers: () => void;
  openCSVUploader: () => void;
}

export default function DriverColumnHeader (
  { setFilterTerm, handleExportDrivers, openCSVUploader }: Readonly<DriverColumnHeaderProps>,
): JSX.Element {
  const { exportDrivers } = useFlags();
  const { hardwareId } = useListActionContext();

  return (
    <header>
      <div className='flex gap-x-4 py-6 px-6'>
        <AddNewDriverButton hardwareId={hardwareId} />
        <input
          type='text'
          placeholder='Filter...'
          className='flex-grow w-full bg-transparent border-b outline-none border-primary'
          onChange={e => {
            setFilterTerm(e.target.value);
          }}
        />
        <div className='flex gap-4 justify-around items-center shrink'>
          <a
            className='flex gap-2 text-black cursor-pointer truncate'
            onClick={openCSVUploader}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                openCSVUploader();
              }
            }}
          >
            <FontAwesomeIcon icon={faFileArrowUp} className='self-center shrink-0' />
            <div className='truncate'>Upload Template</div>
          </a>
          <a className='flex gap-2 text-black truncate' href='/templates/default_driver_template.csv' download>
            <FontAwesomeIcon icon={faFileArrowDown} className='self-center shrink-0' />
            <div className='truncate'>Download Template</div>
          </a>
        </div>
        {exportDrivers && (
          <div
            className='flex gap-x-2 items-center text-black cursor-pointer effra-12 min-w-fit'
            onClick={handleExportDrivers}
          >
            <FontAwesomeIcon icon={faFileArrowDown} />
            Export Drivers
          </div>
        )}
      </div>
    </header>
  );
}
