import type { HardwareIntegration, ModularObject } from '@/__generated__/types';
import { useEditContext } from '@/components';
import ControlledDatePicker from '@/components/form/DatePickerInput/ControlledDatePicker';
import { ParentRangeColor } from '@/components/form/DatePickerInput/DatePickerInput';
import ModuleTextInput from '@/components/modules/ModuleTextInput';
import SelectInput from '@/components/modules/SelectBoxInput';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useGetUserByIdQuery } from '@/components/User/getUserById.generated';
import { useLoggedInSubscription, useLoggedInUser } from '@/hooks/useLoggedInUser';
import { TemplateType } from '@/models/template.model';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { selectRootState } from '@/state/selectors';
import { addIntegrations } from '@/state/slices/integration.slice';
import { addModularObjects } from '@/state/slices/modularObjects.slice';
import { postIntegration, postModularObject } from '@/util/requests.functions';
import { useApolloClient } from '@apollo/client';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimer } from '@fortawesome/sharp-solid-svg-icons';
import { isValid } from 'date-fns';
import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { getTheBandBackTogether } from '../../ModularObject/Card/ObjectCard.util';
import { shapeNewDriver, shapeNewRequirement } from './utils';
import metrics from '@/util/metrics';

export default function RapidDriverCreation (): JSX.Element {
  const { reFetchObservableQueries } = useApolloClient();
  const currentUser = useLoggedInUser();
  const { objectCardData } = useObjectCardContext();
  const isDriver = objectCardData?.template?.type === TemplateType.Driver;
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectRootState);
  const { templates: templateState } = state;
  const { canUserEdit } = useEditContext();
  const subscription = useLoggedInSubscription();

  const methods = useForm({
    defaultValues: {
      driverType: 'Task',
      driverName: '',
      staticDueDate: null,
      staticStartDate: null,
      shallStatement: null,
      duration: null,
    },
  });

  const { setValue, watch } = methods;

  const objectId = useWatch({ name: 'id' });
  const startDate = watch('staticStartDate');
  const endDate = watch('staticDueDate');
  const duration = watch('duration');
  const driverName = watch('driverName');
  const shallStatement = watch('shallStatement');
  const driverType = watch('driverType');

  useEffect(() => {
    if (startDate && endDate) {
      const diff = dayjs(endDate).diff(dayjs(startDate), 'day');
      setValue('duration', diff);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (duration !== null) {
        if (startDate && endDate) {
          setValue('staticDueDate', dayjs(startDate).add(duration, 'day').toDate());
        } else if (startDate && !endDate) {
          setValue('staticDueDate', dayjs(startDate).add(duration, 'day').toDate());
        } else if (!startDate && endDate) {
          setValue('staticStartDate', dayjs(endDate).subtract(duration, 'day').toDate());
        }
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [duration]);

  const ownerId = objectCardData?.template?.type === TemplateType.Driver
    ? objectCardData?.assigneeId
    : objectCardData?.ownerId;

  const { data } = useGetUserByIdQuery({
    variables: { id: ownerId },
    skip: !ownerId,
  });

  const isInternal = currentUser?.organizationId === data?.getUserByID?.organizationId;

  const submitDriver = useCallback(
    async (event): Promise<void> => {
      event.stopPropagation();

      if (event?.key !== 'Enter' && event.type !== 'click') {
        return;
      }

      event.preventDefault();

      if (!driverType) {
        addToastError('Please enter a driver type');
        return;
      }

      if (!driverName) {
        addToastError('Please enter a driver name');
        return;
      }

      let newModularObjectDriver: ModularObject = null;

      if (driverType !== 'Requirement') {
        if (!endDate || !isValid(new Date(endDate))) {
          addToastError('Please enter a due date');
          return;
        }

        newModularObjectDriver = shapeNewDriver({
          subType: driverType,
          name: driverName,
          startDate: startDate ? dayjs(startDate).toISOString() : undefined,
          targetDate: dayjs(endDate).toISOString(),
        });
      } else {
        newModularObjectDriver = shapeNewRequirement({
          subType: driverType,
          name: driverName,
          shall: shallStatement,
        });

        newModularObjectDriver.startDate = objectCardData?.startDate;
        newModularObjectDriver.targetDate = objectCardData?.startDate;
      }

      const newModularObjDrivers = await postModularObject([newModularObjectDriver]);
      const [newDriver] = newModularObjDrivers ?? [];

      if (newDriver) {
        const milestoneIntegration: HardwareIntegration = {
          objectId: newDriver.id,
          objectType: 'modular_object',
          parentId: objectId,
          parentType: 'modular_object',
          id: null,
          createdAt: null,
          createdById: null,
          deletedAt: null,
          isPending: null,
          modifiedAt: null,
        };
        await postIntegration([milestoneIntegration]);

        addToastSuccess('Driver created');
        metrics.track('[Object Card] Rapid driver created', {
          driverType,
          duration,
        });

        const driverTemplate = templateState[newDriver?.templateId];
        newDriver.data = getTheBandBackTogether(driverTemplate?.modules, newDriver?.data);
        dispatch(addModularObjects([newDriver]));
        // We can dispatch this faux integration since we know no approval is needed
        dispatch(addIntegrations([milestoneIntegration]));

        // Refetch queries to update cached data to include new driver
        reFetchObservableQueries().catch(console.error);
      }

      methods.setValue('driverType', driverType);
      methods.setValue('driverName', '');
      methods.setValue('staticStartDate', '');
      methods.setValue('staticDueDate', '');
      methods.setValue('shallStatement', '');
      methods.setValue('duration', '');
      methods.setFocus('driverName', { shouldSelect: true });
    },
    [driverType, driverName, endDate, startDate, shallStatement],
  );

  if (!canUserEdit) {
    return null;
  }

  const DRIVER_TYPES = ['Milestone', 'Requirement', 'Task'];

  const options = DRIVER_TYPES.map((action) => ({
    label: action,
    value: action,
    isProRestricted: action === 'Requirement' && 'OBJECT_TYPE_REQUIREMENTS' in (subscription?.featureLimits ?? {}),
  }));

  let parentRangeColor = ParentRangeColor.Default;

  if (ownerId && !isInternal) {
    parentRangeColor = ParentRangeColor.External;
  } else if (ownerId && isInternal) {
    parentRangeColor = ParentRangeColor.Internal;
  }

  return (
    <FormProvider {...methods}>
      <form
        id='rapid-driver-creation-form'
        className='flex items-center flex-1 gap-1.5 mx-6 @container/form z-[1]'
        onKeyDown={submitDriver}
      >
        <div className='min-w-[92px]'>
          <SelectInput
            name='driverType'
            options={options}
            buttonProps={{ selectedItem: driverType, placeholder: 'Type' }}
            isNotAttribute
            className='min-w-[140px]'
          />
        </div>

        <div className='flex items-center gap-1.5 flex-1 shrink @[650px]/form:gap-1.5'>
          {driverType === 'Requirement' ?
            (
              <>
                <ModuleTextInput
                  className='min-w-[95px]'
                  inputProps={{
                    placeholder: `${isDriver ? 'Nested Driver' : 'Driver'} Name`,
                    name: 'driverName',
                    required: true,
                    autoComplete: 'off',
                    isNotAttribute: true,
                  }}
                  disablePrismaticField
                />
                <ModuleTextInput
                  className='grow'
                  inputProps={{
                    placeholder: 'Shall Statement',
                    name: 'shallStatement',
                    required: false,
                    autoComplete: 'off',
                    isNotAttribute: true,
                  }}
                  disablePrismaticField
                />
              </>
            ) :
            (
              <>
                <ModuleTextInput
                  className='min-w-[140px] grow'
                  inputProps={{
                    placeholder: `${isDriver ? 'Nested Driver' : 'Driver'} Name`,
                    name: 'driverName',
                    required: true,
                    autoComplete: 'off',
                    isNotAttribute: true,
                  }}
                  disablePrismaticField
                />
                <ControlledDatePicker
                  className='min-w-[95px] @[650px]/form:max-w-[115px]'
                  id='staticStartDate'
                  endDate={endDate}
                  parentStartDate={objectCardData?.startDate ? dayjs(objectCardData.startDate).toDate() : null}
                  parentEndDate={objectCardData?.targetDate ? dayjs(objectCardData.targetDate).toDate() : null}
                  parentRangeColor={parentRangeColor}
                  placeholder='Start Date'
                  inputProps={{ isNotAttribute: true }}
                  {...methods.register('staticStartDate')}
                />
                <FontAwesomeIcon className='shrink-0' icon={faTimer} />
                <ModuleTextInput
                  className='min-w-[46px] @[650px]/form:max-w-[55px] @[650px]/form:grow'
                  inputProps={{
                    placeholder: 'Days',
                    className: 'w-[46px] grow',
                    name: 'duration',
                    type: 'number',
                    min: 0,
                    value: duration,
                    onChange: (e) => setValue('duration', Number(e.target.value)),
                    isNotAttribute: true,
                  }}
                  disablePrismaticField
                />
                <FontAwesomeIcon className='shrink-0' icon={faArrowRight} />
                <ControlledDatePicker
                  className='min-w-[95px] @[650px]/form:max-w-[115px]'
                  id='staticDueDate'
                  startDate={startDate}
                  parentStartDate={objectCardData?.startDate ? dayjs(objectCardData.startDate).toDate() : null}
                  parentEndDate={objectCardData?.targetDate ? dayjs(objectCardData.targetDate).toDate() : null}
                  parentRangeColor={parentRangeColor}
                  placeholder='Target Date'
                  inputProps={{ isNotAttribute: true }}
                  {...methods.register('staticDueDate')}
                />
              </>
            )}
        </div>
        <button
          id='rapid-driver-creation-button'
          className='py-3 px-3.5 btn-primary-hollow effra-xs shrink'
          onClick={submitDriver}
        >
          <span className='hidden @[620px]/form:block'>Create</span>
          <span className='block @[620px]/form:hidden'>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </button>
      </form>
    </FormProvider>
  );
}
