import cx from 'classnames';
import { invertObj } from 'ramda';
import { useCallback, useMemo } from 'react';

import FormContainer from '@/components/FormContainer/FormContainer';
import FieldErrorDisplay from './FieldErrorDisplay';

import { Loader } from '@/components/loader';
import { useAppDispatch } from '@/state/hooks';

import type { ModularObject } from '@/__generated__/types';
import {
  useGetUsersByEmailQuery,
} from '@/components/common/Drivers/DriverSection/DriverListActions/AddCollaborators/GetUsersByEmail.query.generated';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { addToastError } from '@/components/Toast/utils';
import { addIntegrations } from '@/state/slices/integration.slice';
import { addModularObjects } from '@/state/slices/modularObjects.slice';
import { addShares } from '@/state/slices/shares.slice';
import { STATUS_OPTIONS } from '@/util/constants';
import dayjs from 'dayjs';
import { type DriverMap, type MappableDriverField, VISIBILITY_OPTIONS } from '../MapHeader/constants';
import { mapToValidDriverType } from '../MapHeader/utils';
import { useBulkDriverCreateMutation } from './bulkTaskCreate.generated';
import getRowErrors from './lib/getRowErrors';

interface FieldErrorDisplayFormProps extends StepContentProps {
  onClose: (error?: boolean) => void;
  onStepChange?: (step: number) => void;
  drivers: DriverMap;
  rows: DriverMap[];
  hardware: ModularObject;
}

export default function FieldErrorDisplayForm ({
  onClose,
  onStepChange,
  hardware,
  resetSteps,
  drivers,
  rows,
}: Readonly<FieldErrorDisplayFormProps>): JSX.Element {
  const dispatch = useAppDispatch();

  const [bulkDriverCreateMutation, { loading }] = useBulkDriverCreateMutation();

  const driverKeyMap = useMemo(
    () => invertObj(drivers) as Record<MappableDriverField, string>,
    [drivers],
  );

  const _emails = useMemo(() =>
    rows.reduce((acc, row) => {
      const collaboratorEmails = row[driverKeyMap.collaboratorEmail]?.split(',') || [];
      const assignee = row[driverKeyMap.assignee];
      if (assignee) {
        collaboratorEmails.push(assignee);
      }
      collaboratorEmails.forEach(email => acc.add(email.toLowerCase().trim()));
      return acc;
    }, new Set<string>()), [rows, driverKeyMap.assignee, driverKeyMap.collaboratorEmail]);

  const { data: userData } = useGetUsersByEmailQuery({
    variables: { emails: [..._emails.values()] },
    skip: !_emails.size,
  });
  const emails = useMemo(() => userData?.getUsersByEmail.map(user => user.email) || [], [userData]);

  const { body, errors } = useMemo(
    () => {
      const bulkDriverCreateInput = [];

      let unnamedTaskCount = 0;
      const errors = {};

      let lastParentTaskIdx = 0;
      rows.forEach((row, idx) => {
        const rowErrors = getRowErrors(row, driverKeyMap, emails);

        if (rowErrors[driverKeyMap.name]) {
          errors[`Unnamed Driver ${++unnamedTaskCount}`] = [rowErrors[driverKeyMap.name]];
          // Set lastParentTaskIdx to null until a valid task is found, so we don't create subtasks under the wrong task
          lastParentTaskIdx = null;
          return;
        } else if (Object.keys(rowErrors).length) {
          errors[row[driverKeyMap.name]] = Object.values(rowErrors);
        }

        // Combine collaborators into an array
        const collaboratorRoles = row[driverKeyMap.collaboratorRole]?.split(',').map((role) =>
          `${role.trim().charAt(0).toUpperCase()}${role.trim().slice(1).toLowerCase()}`
        ) || [];
        let cdx = 0;
        const collaborators = (row[driverKeyMap.collaboratorEmail]?.split(',') || []).reduce(
          (acc, collaboratorEmail) => {
            const email = collaboratorEmail.toLowerCase().trim();

            // Removes emails that don't exist
            if (emails.includes(email)) {
              acc.push({
                email,
                role: collaboratorRoles[cdx] || 'Viewer',
              });
            }

            cdx++;
            return acc;
          },
          [],
        );

        // Remove assignee if they don't exist
        const assignee = emails.includes(row[driverKeyMap.assignee]?.toLowerCase().trim())
          ? row[driverKeyMap.assignee]
          : null;

        // Add assignee as a collaborator if they're not already
        if (
          assignee && !collaborators.find((collaborator) =>
            collaborator.email === assignee
          )
        ) {
          collaborators.push({ email: assignee, role: 'Viewer' });
        }

        const driver = {
          name: row[driverKeyMap.name],
          description: row[driverKeyMap.description],
          startDate: row[driverKeyMap.startDate] ? dayjs(row[driverKeyMap.startDate])?.toISOString() : null,
          targetDate: row[driverKeyMap.targetDate] ? dayjs(row[driverKeyMap.targetDate])?.toISOString() : null,
          status: (row[driverKeyMap.status] ?? 'To Do')?.toUpperCase().replace(' ', '_'),
          visibility: row[driverKeyMap.visibility]?.toUpperCase() || 'PRIVATE',
          resourceCost: row[driverKeyMap.resourceCost],
          assignee,
          collaborators,
          subTasks: [],
          type: '',
        };

        // Error correcting
        // Check for incorrect visibility options
        if (
          !VISIBILITY_OPTIONS.map(visibility => visibility.toUpperCase()).includes(
            row[driverKeyMap.visibility]?.toUpperCase(),
          )
        ) {
          driver.visibility = 'PRIVATE';
        }

        // Check for incorrect task statuses
        if (!STATUS_OPTIONS.map(status => status.toUpperCase()).includes(row[driverKeyMap.status]?.toUpperCase())) {
          driver.status = 'TO_DO';
        }

        const isSubDriver = Boolean(row[driverKeyMap.subDriverType]);
        // Use the task type if not a subtask
        if (!isSubDriver) {
          driver.type = mapToValidDriverType(row[driverKeyMap.type]).toUpperCase();
        }

        // Check for incorrect subtask statuses
        if (isSubDriver) {
          driver.type = mapToValidDriverType(row[driverKeyMap.subDriverType]).toUpperCase();

          // Insert subtask into parent task
          if (lastParentTaskIdx !== null) {
            bulkDriverCreateInput[lastParentTaskIdx]?.subTasks.push(driver);
          }
          return;
        }

        bulkDriverCreateInput.push(driver);
        // Update lastParentTaskIdx to the last task that was created
        lastParentTaskIdx = bulkDriverCreateInput.length - 1;
      });

      return { body: bulkDriverCreateInput, errors };
    },
    [rows, driverKeyMap, emails],
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        await bulkDriverCreateMutation({
          variables: {
            input: {
              objectId: hardware.id,
              drivers: body,
            },
          },
          onCompleted: async (data) => {
            const integrations = [];
            const shares = [];
            if (data?.bulkDriverCreate) {
              const modularObjects = data.bulkDriverCreate.map(modularObject => {
                integrations.push(...modularObject.integrations);
                if (modularObject.shares.length) {
                  shares.push(...modularObject.shares);
                }

                delete modularObject.integrations;
                delete modularObject.shares;
                return modularObject;
              });

              dispatch(addModularObjects(modularObjects));
              dispatch(addIntegrations(integrations));
              dispatch(addShares(shares));
            }
            onClose(true);
          },
          refetchQueries: ['GetObjectCardObject'],
        });
      } catch (error) {
        console.error(error);
        addToastError('Error uploading drivers');
        addToastError(error.message);
      }
    },
    [body, dispatch],
  );

  const headerText = useMemo(() => {
    if (Object.keys(errors)?.length) {
      if (!body.length) {
        return `Error! No drivers can be created. Please fix the errors and try again.`;
      }
      return `Success! ${body.length} drivers can be created, but a few still need attention.`;
    }

    return `Success! ${body.length} drivers can be created.`;
  }, [errors, body]);

  return (
    <article className='relative'>
      <FormContainer
        handleSubmit={handleSubmit}
        headerText={headerText}
        onClose={onClose}
        content={<FieldErrorDisplay errors={errors} driverKeyMap={driverKeyMap} />}
        buttons={
          <>
            <button
              type='button'
              className='btn-ghost'
              onClick={() => {
                onStepChange(1);
                resetSteps();
              }}
            >
              start over
            </button>
            <button
              disabled={loading}
              className={cx('flex items-center justify-center ml-2 btn-primary', {
                'cursor-not-allowed bg-gray-400 text-gray-600 hover:bg-gray-400': loading,
              })}
            >
              <Loader
                className={cx('w-[30px] h-[30px]', {
                  hidden: !loading,
                })}
              />
              <span>create {body.length} drivers</span>
            </button>
          </>
        }
      />
    </article>
  );
}
