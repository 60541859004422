import LoadingSpinner from '@/components/common/Drivers/DriverSection/LoadingSpinner';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { Button } from '@/designSystemComponents/Button';
import { useCallback, useState } from 'react';
import MegaModal from '../../MegaModal/MegaModal';
import type { EditableDependency } from './DependencyConfigScreen/DependencyConfigScreen';
import { useGetModularObjectNamesByIDsQuery } from './DependencyConfigScreen/getModularObjectNamesByIDs.generated';
import { type GetGapDataQuery, useGetGapDataQuery } from './getGapData.generated';

interface ConfirmReorderObjectsScreenProps extends StepContentProps {
  currentDependencies: string[];
  selectedIds: string[];
  currentObjectId: string;
  isBlockingDependency: boolean;
  dependencyIdToEdit?: Readonly<EditableDependency>;
  setGapDataResponse: (data: GetGapDataQuery) => void;
  closeModal: () => void;
}

export default function ConfirmReorderObjectsScreen ({
  incrementStep,
  selectedIds,
  currentDependencies,
  currentObjectId,
  isBlockingDependency,
  dependencyIdToEdit,
  setGapDataResponse,
  closeModal,
}: Readonly<ConfirmReorderObjectsScreenProps>) {
  const isEditMode = Boolean(dependencyIdToEdit);

  const [movingObjectName, setMovingObjectName] = useState<string>('Moving Object');
  const [stationaryObjectName, setStationaryObjectName] = useState<string>('Stationary Object');

  const descriptionOfMove = isBlockingDependency ? 'before' : 'after';

  const dependencyInputs = selectedIds.filter(id => !currentDependencies?.includes(id)).map(id => {
    return isBlockingDependency
      ? { modularObjectId: id, blockedById: currentObjectId }
      : { modularObjectId: currentObjectId, blockedById: id };
  });

  const skipDataLoad = !currentObjectId || isEditMode;

  useGetModularObjectNamesByIDsQuery({
    variables: {
      ids: [
        dependencyInputs[0].modularObjectId,
        dependencyInputs[0].blockedById,
      ],
    },
    skip: skipDataLoad,
    onCompleted: data => {
      data.getModularObjectByIDs.forEach(modularObject => {
        if (modularObject.id === dependencyInputs[0].modularObjectId) {
          if (isBlockingDependency) {
            setStationaryObjectName(modularObject.name);
          } else {
            setMovingObjectName(modularObject.name);
          }
        } else if (modularObject.id === dependencyInputs[0].blockedById) {
          if (isBlockingDependency) {
            setMovingObjectName(modularObject.name);
          } else {
            setStationaryObjectName(modularObject.name);
          }
        }
      });
    },
  });

  const { loading: isLoadingResults } = useGetGapDataQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        throughModularObjectId: currentObjectId,
        dependencyInputs,
      },
    },
    skip: skipDataLoad,
    onCompleted: data => {
      setGapDataResponse(data);
      // No further need for this screen if adding multiple dependencies
      if (dependencyInputs.length !== 1) {
        incrementStep();
      }
      const reverses = data.getDependencyGapCalcs.length === 1 && data.getDependencyGapCalcs[0].reversesOrder;
      if (!reverses) {
        incrementStep();
      }
    },
  });

  // No need for this screen if editing a dependency
  if (skipDataLoad) {
    incrementStep();
  }

  const handleIncrementStep = useCallback(() => {
    incrementStep();
  }, []);

  // Keeps a flicker of "no results" content showing while the data fetch happens
  if (isLoadingResults) {
    return (
      <MegaModal className='flex justify-center items-center h-[551px] w-[1090px]'>
        <LoadingSpinner isLoading={true} />
      </MegaModal>
    );
  }

  return (
    <div className='flex flex-col flex-1 gap-5'>
      <h3 className='mb-0 text-3xl font-black capitalize font-effra text-secondary'>Dependencies Out of Order</h3>
      <p>
        You&apos;re trying to make a blocking relationship that looks out of order. To fix this, we&apos;re going to
        <span className='font-bold'>
          {' '}
          move {' ' + movingObjectName + ' '}
          {' ' + descriptionOfMove + ' '}
          {' ' + stationaryObjectName + ' '}
        </span>
        which may cause any dependencies connected to {' ' + movingObjectName + ' '} to change too.
      </p>
      <p>Are you ok with this?</p>
      <div className='flex gap-5'>
        <Button figmaProps={{ style: 'outline', size: 'large' }} className='flex-1' onClick={closeModal}>
          Cancel
        </Button>

        <Button figmaProps={{ style: 'fill', size: 'large' }} className='flex-1' onClick={handleIncrementStep}>
          Yes, Proceed
        </Button>
      </div>
    </div>
  );
}
